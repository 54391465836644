import React from "react";
import StepIcon from "../../components/step icon/StepIcon";
import "./steps-styles.scss";
import approvedImage from "../../assets/images/approved.svg";
import clickImage from "../../assets/images/click.svg";
import moneyImage from "../../assets/images/money.svg";
import { useSelector } from "react-redux";
import {selectTextData } from "../../redux/user/userSlice";
import TitleDot from "../../components/title with dot/TitleDot";
import { Link } from "react-router-dom";
function Steps() {
  const textData = useSelector(selectTextData);
  return (
    !textData.homePage.steps ? null :
    <section id="steps" className="app__section flex-center-c">
      <TitleDot>{textData.homePage.steps.title}</TitleDot>
      <div id="stepsContainer" className="flex-center-c">
        {textData.homePage.steps.steps.map((item, index) => (
          <StepIcon
            key={"step" + index}
            time={index}
            image={
              index === 0
                ? clickImage
                : index === 1
                ? approvedImage
                : moneyImage
            }
          >
            <h3>{item.title}</h3> <p>{item.p}{item.link && <Link to={item.link.href}>{item.link.text}</Link>}</p>
          </StepIcon>
        ))}
      </div>
    </section>
  )
}

export default Steps;
