import React, { useEffect, useState } from "react"
import TouchFlash from "../touch flash/TouchFlash"
import "./slider-styles.scss"
function Slider(props) {
  const bulletsArray = new Array(props.totalSlides).fill(" ")
  const [openTab, setOpenTab] = useState(0)
  const [isActive, setisActive] = useState(false)
  const [distance, setdistance] = useState(1)
  const handleTabClick = item => {
    setdistance(openTab - item)
    setOpenTab(item)
  }
  const spanTabStylesOpen = {
    "--distance": distance,
    animationName: "decoAnimation",
  }
  const spanTabStylesClosed = { animation: "none" }
  const [startTouch, setStartTouch] = useState(0)
  const [endTouch, setEndTouch] = useState(0)
  const [touchevent, settouchevent] = useState()
  const handleTouches = e => {
    settouchevent(e)
    if (e.type === "touchstart") {
      setisActive(true)
      setStartTouch(e.changedTouches[0].clientX)
    } else if (e.type === "mousedown") {
      setisActive(true)
      setStartTouch(e.clientX)
    } else if (e.type === "touchend") {
      setisActive(false)
      setEndTouch(e.changedTouches[0].clientX)
    } else if (e.type === "mouseup") {
      setisActive(false)
      setEndTouch(e.clientX)
    } else if (e.type === "mouseleave") {
      setisActive(false)
    }
  }

  useEffect(() => {
    if (
      endTouch < startTouch &&
      startTouch - endTouch > 50 &&
      openTab < bulletsArray.length - 1
    ) {
      setOpenTab(openTab + 1)
      setdistance(-1)
    } else if (
      endTouch > startTouch &&
      openTab > 0 &&
      endTouch - startTouch > 50
    ) {
      setOpenTab(openTab - 1)
      setdistance(1)
    } else if (
      endTouch > startTouch &&
      openTab === 0 &&
      endTouch - startTouch > 50
    ) {
      setOpenTab(bulletsArray.length - 1)
      setdistance(-(bulletsArray.length - 1))
    } else if (endTouch < startTouch && startTouch - endTouch > 50) {
      setOpenTab(0)
      setdistance(bulletsArray.length - 1)
    }
  }, [endTouch])
  const [winWidth, setwinWidth] = useState(
    window.innerWidth > 800 ? 800 : window.innerWidth - 40
  )

  useEffect(() => {
    setwinWidth(window.innerWidth > 800 ? 800 : window.innerWidth - 40)
  }, [window.innerWidth])
  useEffect(() => {
    let inter
    if (props.autoScroll) {
      inter = setInterval(() => {
        if (openTab < props.totalSlides - 1) {
          setOpenTab(openTab + 1)
          setdistance(-1)
        } else {
          setOpenTab(0)
          setdistance(bulletsArray.length - 1)
        }
      }, 7000)
    }
    return () => {
      window.clearInterval(inter)
    }
  }, [openTab])
  return (
    <div className="slider-wrapper">
      {props.hasTopButtons && (
        <div
          className={`flex-center-r tab-buttons-wrapper ${
            props.hasSeperator && "seperator"
          }`}
        >
          {props.hasTopButtons.map((item, index) => (
            <button
              aria-label={`Show slide number ${index + 1}`}
              className="generic-button btnNaked"
              key={"tab-button" + index}
              onClick={() => handleTabClick(index)}
              style={{
                fontWeight: openTab === index ? "bold" : "normal",
                color: openTab === index ? "black" : "#787878",
              }}
            >
              {props.icons && (
                <img
                  src={props.icons[index]}
                  alt={props.hasTopButtons[index]}
                  height="24px"
                  style={{ opacity: openTab === index ? "1" : "0.5" }}
                />
              )}
              {item}
              {openTab === index && <span className="button-underline"></span>}
            </button>
          ))}
        </div>
      )}
      <div
        className="slider draggable flex-center-c"
        onTouchStart={handleTouches}
        onTouchMove={handleTouches}
        onTouchEnd={handleTouches}
        onMouseDown={handleTouches}
        onMouseMove={handleTouches}
        onMouseUp={handleTouches}
        onMouseLeave={handleTouches}
      >
        <TouchFlash ev={touchevent} isActive={isActive} />

        <div
          className="slides-container"
          style={{ transform: `translateX(${openTab * -winWidth}px)` }}
        >
          {props.children}
        </div>
      </div>
      <div
        className="flex-center-r"
        style={{ marginTop: "15px", padding: "5px" }}
      >
        {bulletsArray.map((_, index) => (
          <button
            aria-label={`Show slide number ${index + 1}`}
            key={"bullet" + index}
            className="selected-tab-button-wrapper flex-center-r"
            onClick={() => handleTabClick(index)}
            style={openTab === index ? spanTabStylesOpen : spanTabStylesClosed}
          >
            {openTab === index && (
              <span
                style={spanTabStylesOpen}
                className="selected-tab-button-decoration"
              ></span>
            )}
          </button>
        ))}
      </div>
    </div>
  )
}
export default Slider
