import { createSlice } from '@reduxjs/toolkit';
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    language: 'english',
    country: 'com',
    textData: {},
  },
  reducers: {
    setUserName: (state, action) => {
      state.user = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload.language;
      state.country = action.payload.country;
    },
    setText: (state, action) => {
      state.textData = action.payload;
    },
  },
});
export const { setUserName, setLanguage, setText } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectLanguage = (state) => state.user.language;
export const selectCountry = (state) => state.user.country;
export const selectTextData = (state) => state.user.textData;
export default userSlice.reducer;
