import React, { useEffect, useRef } from "react";
import { useLocation, withRouter } from "react-router-dom";
import Arrow from "../../assets/images/arrow-up.svg";
import "./to-top-styles.scss";
function ToTop() {
  const refButton = useRef();
  const { key } = useLocation();
  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.5, 1.0],
    };
    let observer = new IntersectionObserver((entries, observer) => {
      if (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.5) {
            refButton.current.style.opacity = 0;
            refButton.current.style.pointerEvents = "none";
          } else {
            refButton.current.style.opacity = 1;
            refButton.current.style.pointerEvents = "all";
          }
        });
      }
    }, options);
    observer.observe(document.querySelector(".checker"));
    return () => {
      observer.disconnect();
    };
  }, [key]);

  return (
    <button
      ref={refButton}
      className="flex-center-c"
      id="to-top-button"
      onClick={() => window.scrollTo(0, 0)}
      aria-label='Scroll to top'
    >
      <img src={Arrow} alt="" />
    </button>
  );
}
export default withRouter(ToTop);
