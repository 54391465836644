import React, { useState, useEffect, useRef } from "react";
import "./contact-styles.scss";
import { useSelector } from "react-redux";
import { selectTextData } from "../../redux/user/userSlice";
import TitleDot from "../../components/title with dot/TitleDot";
import { useLocation } from "react-router";

function Contact() {
  const textData = useSelector(selectTextData);
  const [selectedInput, setselectedInput] = useState(-1);
  const fields = textData.homePage.contactUs.fields;
  let temp = {};      
  Object.keys(fields).forEach((item) => (temp[item] = ""));
  const [inputArrayValues, setinputArrayValues] = useState(temp);
  const { hash, key } = useLocation();
  const idRef = useRef();
  useEffect(() => {
    if (idRef.current && hash.slice(1) === "contact") {
      idRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [key, hash]);
  const handleChange = (e) => {
    setinputArrayValues({
      ...inputArrayValues,
      [e.target.name]: e.target.value,
    });
  };
  const selectedStyles = { transform: "translateY(0%) scale(.8)" };
  const notSelectedStyles = { transform: "translateY(150%)" };
  return (!fields ? null :
    <section ref={idRef} id="contact" className="app__section flex-center-c">
      <TitleDot>{textData.homePage.contactUs.title}</TitleDot>
      <div className="flex-center-r inputs-container">
        {Object.keys(fields).map((item, index) => (
          <div className="input-row flex-center-c" key={`input_${index}`}>
            <label
              htmlFor={item}
              className="custom__label"
              style={
                selectedInput === index || inputArrayValues[item] !== ""
                  ? selectedStyles
                  : notSelectedStyles
              }
            >
              {fields[item]}
            </label>
            <input
              type="text"
              id={item}
              name={item}
              value={inputArrayValues[Object.keys(fields)[index]]}
              onChange={handleChange}
              maxLength="255"
              onFocus={() => setselectedInput(index)}
              onBlur={() => setselectedInput(-1)}
            />
          </div>
        ))}
        <div className="input-row flex-center-c">
          <a
            className="generic-button btnGhost"
            href={`mailto:affiliates@winmasters.com?subject=Contact_form&body=${
              "Name: " +
              inputArrayValues["name"] + 
              "%0D%0A%0D%0A" +
              "Surname: " +
              inputArrayValues["surname"] + 
              "%0D%0A%0D%0A" +
              "Email: " +
              inputArrayValues["email"] +
              "%0D%0A%0D%0A" +
              "Message: " +
              "%0D%0A" +
              inputArrayValues["message"] 
            }`}
          >
            {textData.homePage.contactUs.button}
          </a>
        </div>
      </div>
    </section>
  )
}
export default Contact;
