import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { setLanguage } from '../redux/user/userSlice';

const useLocalStoreLanguage = () => {
  const dispatch = useDispatch();
  const { search, hash } = useLocation();
  const languageChecker = () => {
    const localData = {
      country: localStorage.getItem('preferedCountry'),
      language: localStorage.getItem('preferedLanguage'),
    };
    return localData;
  };
  const checkIfProperLanguageQuery = (urlSearch) => {
    let country = null,
      language = null;
    if (typeof urlSearch === 'string') {
      const temp = urlSearch.split('?')[1].split('&');
      country = temp[0].split('=')[1];
      language = temp[1].split('=')[1];
    } else {
      country = urlSearch.country;
      language = urlSearch.language;
    }
    const availableCountries = ['com', 'greece', 'romania', 'cyprus'];
    const availableLanguages = ['english', 'native'];
    if (
      availableCountries.includes(country) &&
      availableLanguages.includes(language)
    ) {
      localStorage.setItem('preferedCountry', country);
      localStorage.setItem('preferedLanguage', language);
      dispatch(setLanguage({ country, language }));
    } else {
      localStorage.setItem('preferedCountry', 'com');
      localStorage.setItem('preferedLanguage', 'english');
      dispatch(setLanguage({ country, language }));
    }
  };
  useEffect(() => {
    if (!search.includes('?') && !hash.includes('?')) {
      const data = languageChecker();
      return checkIfProperLanguageQuery(data);
    }
    if (search.includes('country=') && search.includes('language=')) {
      return checkIfProperLanguageQuery(search);
    }
    if (hash.includes('country=') && hash.includes('language=')) {
      return checkIfProperLanguageQuery(hash);
    }
  }, []);
  return null;
};

export default useLocalStoreLanguage;
