import  { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsMobile } from "../redux/navigator/navigatorSlice";


const useWindowSize = ()=>{
const dispatch = useDispatch()
    const runWindowSizeCheck = () => {
        dispatch(setIsMobile(window.innerWidth > 1250 ? false : true))
      }
      useEffect(() => {
        window.addEventListener("resize", runWindowSizeCheck, true)
        runWindowSizeCheck()
      }, [])
  return null;
}

export default useWindowSize