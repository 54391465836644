import {Suspense, lazy } from "react"
import Navigation from "./components/navigation/Navigation"
import Footer from "./components/footer/Footer"
import { Route, Switch, useLocation } from "react-router-dom"
import ToTop from "./components/to top button/ToTop"
import { AnimatePresence } from "framer-motion"
import HomePage from "./pages/Home page/HomePage"
import Loader from "./components/loader/Loader"
import useLocalStoreLanguage from "./helpers/useLocalStoreLanguage"
import useWindowSize from "./helpers/useWindowSize"
import useUpdateTexts from "./helpers/useUpdateTexts"

function App() {
  //lazy load the pages when needed
  const TermsPage = lazy(() => import("./pages/Fullpage terms/Terms"))
  const FaqPage = lazy(() => import("./pages/Faq page/FaqPage"))
  const RewardTiersPage = lazy(() => import("./pages/reward tiers/RewardTiersPage"))
  const RegistrationPage = lazy(() => import("./pages/Registration page/RegistrationPage"))
  //custom hooks+++++++++++++++++++
  //check if url language query is present and store/retrive info from local storage
  useLocalStoreLanguage()
  //window resize eventlistener
  useWindowSize()
  //if language in redux store changed updates the texts for the website
  useUpdateTexts()
   //custom hooks------------------

  const location = useLocation()
  return (
    <>
      <Navigation />
      <ToTop />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Suspense fallback={<Loader />}>
          <Switch location={location} key={location.pathname}>
            <Route exact path={`/terms-and-conditions`}>
              <TermsPage /> <Footer />
            </Route>
            <Route exact path={`/register`}>
              <RegistrationPage /> <Footer />
            </Route>
            <Route exact path={`/faq`}>
              <FaqPage /> <Footer />
            </Route>
            <Route exact path={`/reward-tiers`}>
              <RewardTiersPage /> <Footer />
            </Route>
            <Route exact path={`/`}>
              <HomePage /> <Footer />
            </Route>
            <Route path={`/`}>
              <HomePage /> <Footer />
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </>
  )
}
export default App
