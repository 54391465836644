import React, { useEffect, useRef } from "react";
import "./testimonials-styles.scss";
import { useSelector } from "react-redux";
import {  selectTextData } from "../../redux/user/userSlice";
import Slider from "../../components/slider/Slider";
import { useLocation } from "react-router";
function Testimonials() {
  const textData = useSelector(selectTextData);
  const { hash, key } = useLocation();
  const idRef = useRef();
  useEffect(() => {
    if (idRef.current && hash.includes("testimonials")) {
      idRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [key]);

  return (
    !textData.homePage.testimonials ? null :
    <section
      id="testimonials"
      ref={idRef}
      className="app__section flex-center-c"
    >
      <span className="circle-decoration"></span>

      <Slider
        autoScroll={true}
        totalSlides={textData.homePage.testimonials.length}
      >
        {textData.homePage.testimonials.map((testimonial, index) => (
          <article
            key={"testimonial" + index}
            className="testimonial flex-center-c slider-item"
          >
            <p>{testimonial.paragraph}</p>
            <a href={testimonial.linkUrl}>{testimonial.linkText}</a>
          </article>
        ))}
      </Slider>
    </section>
  )
}

export default Testimonials;
