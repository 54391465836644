import React, { useEffect } from "react";
import "./home-page-styles.scss";
import Hero from "../../sections/hero/Hero";
import Testimonials from "../../sections/testimonials/Testimonials";
import Steps from "../../sections/steps/Steps";
import AboutUs from "../../sections/about us/AboutUs";
import WhatWeOffer from "../../sections/what we offer/WhatWeOffer";
import Contact from "../../sections/contact/Contact";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectTextData } from "../../redux/user/userSlice";

function HomePage() {
  const textData = useSelector(selectTextData);
  useEffect(() => {
    document.querySelector("html head title").innerHTML =
      "winmasters Affiliates | Home";
  }, []);
  return (!textData.homePage ? null :
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut", delay: 0.3 }}
      className="page-wrapper home"
    >
      <Hero />
      <Steps />
      <AboutUs />
      <WhatWeOffer />
      <Testimonials />
      <Contact textData={textData}/>
    </motion.div>
  )
}
export default HomePage;
