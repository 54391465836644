const currentYear = new Date().getFullYear();
const texts = {
  com: {
    english: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Login',
          loginLink: 'https://partners.winmasters.com/en/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/en/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/en/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Home',
          aboutUs: 'About us',
          whatWeOffer: 'Products',
          testimonials: 'Testimonials',
          contact: 'Contact us',
          rewardTiers: 'Reward tiers',
          faq: 'FAQ',
          terms: 'Terms & Conditions',
        },
      },
      footer: {
        sponsors: 'Golden Sponsor',
        socialMedia: 'Social Media',
        // socialLink: {
        //   facebook: 'https://www.facebook.com/winmasters.greece',
        //   instagram: 'https://www.instagram.com/winmasters.gr/',
        //   youtube: 'https://www.youtube.com/channel/UCdiZHVzKgia9R_T80zeoupw',
        // },
        copyright: `© ${currentYear} winmasters is a registered trademark. All rights reserved.`,
      },
      homePage: {
        hero: {
          title: ['Turn your audience into'],
          typing: ['partners.', 'income.', 'a business.'],
          cta: 'Register',
        },
        steps: {
          title: 'You are 3 steps away from earning your first revenue',
          steps: [
            {
              iconUrl: '',
              title: 'Register',
              p: 'All you have to do is fill in your details in the “Sign up” page and submit the registration form.',
              link: {
                text: ' Register now!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Get Approved',
              p: 'Your application will be reviewed by our Affiliate Team and if you meet the requirements you will be accepted.',
            },
            {
              iconUrl: '',
              title: 'Get Revenue',
              p: 'After your application is approved you will be able to start promoting winmasters and get instant revenue.',
            },
          ],
        },
        aboutUs: {
          title: 'About us',
          paragraphs: [
            'winmasters Affiliates team has a real understanding of affiliate marketing and aims to provide a top level professional support to its affiliates.',
            'winmasters team consists of people with many years of knowledge in the online gaming industry and affiliate marketing. This is a “sure bet” for every partner that would like to cooperate with winmasters.',
          ],
          subtitle: 'Our team’s principles',
          list: [
            'Industry-leading revenue generating affiliate program',
            'Up to 50% revenue share commission across all winmasters products',
            'Local approach for customers and affiliates, with support in 4 different languages',
            'Deep knowledge of each respective market and its needs',
            'Our team will provide you with the best support and knowledge in order to achieve your affiliate goals and maximize your profit.',
          ],
        },
        whatWeOffer: {
          title: 'What we offer',
          tabTitles: ['Sportsbook', 'Casino'],
          articles: [
            {
              for: 'sportsbook',
              titles: ['Competitive Odds', 'Live Betting'],
              paragraphs: [
                [
                  'winmasters prices are amongst the most competitive in the market. We also cover a wide range of markets, ranging from the biggest global events to local leagues and minor sports across Europe and worldwide. winmasters aims to provide the most interesting global and local offering to all customers.',
                ],
                [
                  'Live betting is one of the most popular options for our customers and that’s why we offer more than 25.000 events per month all over the year.',
                ],
              ],
            },
            {
              for: 'casino',
              titles: ['Why winmasters Casino?', 'Welcome Bonus & Promotions'],
              paragraphs: [
                [
                  'Fun is guaranteed. Casino and slots lobbies, plus our live Casino with live dealers we offer an extensive portfolio of games, generous welcome bonuses and promotions on a regular basis. Great jackpots amongst the higher you can find around.',
                  'winmasters Casino offers the best of breed games from the top providers such as NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. We are offering a top level online Casino experience with the newest games in the market and following its latest trends. Three different casino destinations are available creating the ideal virtual “casino world”. Live the real casino experience online!',
                ],
                [
                  'Casino welcome bonus is studied to be enjoyed from any customer of any nationality of any preference and taste. Free spins, deposit bonus, tournaments are often offered to the regulars.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Best Bookmakers',
            paragraph:
              '"If you’re looking for a lucrative partnership, you can’t go wrong with winmasters Affiliates. The team is very friendly and helpful, always ready to help us. We believe we will have strong and long-term partnership with them!"',
            linkUrl: 'https://bestbookmakers.co.uk/',
            linkText: 'bestbookmakers.co.uk',
          },
          {
            title: 'bookmakers',
            paragraph:
              '"winmasters are starting to leave their mark on the iGaming industry and it is truly a pleasure cooperating with such an innovating brand. They offer a high conversion rate and are readily available to answer any question you may have."',
            linkUrl: 'https://www.bookmakers.bet/',
            linkText: 'www.bookmakers.bet',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"Winmasters is one of the fastest growing betting companies in the European market. We unreservedly recommend working with such a professional and innovative brand."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
        ],
        contactUs: {
          title: 'Contact us',
          button: 'Send',
          fields: {
            name: 'Name',
            surname: 'Surname',
            email: 'Email',
            message: 'Message',
          },
        },
      },
      termsandconditions: {
        title: ['Terms and Conditions'],

        languageSelectors: {
          selectCountry: 'Select the country',
          selectLanguage: 'Select the language',
          countries: {
            Greece: {
              country: 'Greece',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Romania: {
              country: 'Romania',
              languages: {
                english: 'English',
                native: 'Romanian',
              },
            },
            Cyprus: {
              country: 'Cyprus',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'English',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Reward Tiers'],
        descriptions: [
          'Net Revenue**',
          'Net Revenue Share',
          'Commission Booster*',
          'Earnings total',
        ],
        tabTitles: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
        tierArticles: [
          {
            tier: 'Tier 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Tier 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Tier 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Tier 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Tier 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Get a 5% Commission Booster when you send us 40+ First Time Active Players within a calendar month!',
        earnings: {
          title: 'How much you can earn',
          info: [
            {
              description: 'Sub-Affiliate Commission 5 %',
              amount: 'No limit!',
            },
            {
              description: 'CPA/Hybrid Rewards €€€',
              amount: 'No limit!',
            },
          ],
        },
        extras: {
          title: 'Tiered Revenue Share Plan',
          sections: [
            {
              paragraphs: [
                'Each calendar month, you will earn a percentage of the net revenue from all the players you have referred to winmasters.',
                'The percentage of net revenue share which you will be paid each month depends on two criteria: net revenue and new depositing players (see Commission Booster section below).',
                'As the players you refer generate net revenue, you are paid a percentage of this as a commission. When your referred players generate a total of €10,000 or more within a calendar month, the percentage which you receive will increase from 25% to 30%. When they generate more than €50,000, your net revenue share will increase to 45%.',
                'If the revenue from your players is negative in any month it will be carried over to the following month (this is often called negative carryover).',
                '**Net Revenue is defined as follows: Gross Revenue less Cost of Sales (comprising taxes, network fees, transaction fees, bonus costs and premiums).',
              ],
            },
            {
              title: 'Commission Booster',
              paragraphs: [
                'Refer 40 or more First Time Active players each month and get a 5% bonus on your net revenue share.',
              ],
            },
            {
              title: 'Sub-Affiliate Commission',
              paragraphs: [
                'You can also earn a sub-affiliate commission of 5% by recruiting another affiliate to the winmasters Affiliates program.',
                `The commission is calculated on your sub-affiliates' rewards and added to your monthly commission.`,
              ],
            },
            {
              title: 'CPA/Hybrid Deals',
              paragraphs: [
                'If you are interested in discussing a cost-per-acquisition (CPA) or hybrid reward plan, please contact your affiliate manager.',
              ],
            },
            {
              title: 'Affiliate Payment Options',
              paragraphs: [
                'Bank transfer and Skrill (formerly Moneybookers). ',
              ],
            },
          ],
        },
        disclaimer2:
          'Please note that in some cases depending on the country that you are based in, some of these options may not be available.',
      },
      faq: {
        title: [' Frequently Asked Questions'],
        sections: [
          {
            title: 'General',
            qanda: [
              {
                question: 'How does the winmasters Affiliate program work?',
                answer: [
                  `The winmasters Affiliate program rewards third parties for referring
                                customers to winmasters website.`,
                  `winmasters Affiliates send traffic to our sites via tracked links,
                                and when those users go on to register as customers, deposit and
                                play with us, we reward the affiliates with a commission.`,
                ],
              },
              {
                question: 'What is a sub-affiliate?',
                answer: [
                  `    A sub-affiliate is a new affiliate which you refer to
                                affiliates.winmasters.com. You can do this by getting them to sign
                                up via the new affiliate link which you will find in your account.`,
                  ` Once they have signed up, you can achieve additional earnings for
                                every customer that your sub-affiliate sends to us. You can find
                                your Sub-Affiliate Link under your account summary.`,
                ],
              },
            ],
          },
          {
            title: 'My Account',
            qanda: [
              {
                question: 'Where can I check my account statistics?',
                answer: [
                  `       When you log in to your winmasters Affiliates account, you will be
                                able to access a wide range of reports that detail the traffic that
                                has been sent to us via your site together with any transactions
                                that have been carried out.`,
                  `  If you have any questions regarding these reports then please use
                                our contact form or get in touch with your affiliate manager
                                directly.`,
                ],
              },
              {
                question: 'How often are my statistics updated?',
                answer: [
                  `  The reports in the winmasters Affiliates interface are updated
                            daily, usually by 3pm GMT/4pm BST. In the case that you believe that
                            your statistics have not been updated or you have any questions,
                            please contact your affiliate manager.`,
                ],
              },
              {
                question: 'How are the users linked to my account?',
                answer: [
                  `   When a customer clicks a link or banner on your website, your unique
                                tracking code,it means that client can only be connected to your
                                account.`,
                  ` For more detailed information on how this works, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question:
                  'How can I check the number of users I have referred to winmasters?',
                answer: [
                  `    As an affiliate you have access to customer reporting, which you can
                                    find when you log in to your account. This will give you information
                                    on players but please be aware that their personal information will
                                    not be shared due to the Data Protection Act.`,
                ],
              },
              {
                question:
                  'Are there any countries where I cannot promote winmasters?',
                answer: [
                  `    Players from the following territories are not accepted at
                                        winmasters:`,
                  `United States of America and France.`,
                ],
              },
              {
                question: 'How can I check my sub-affiliates’ statistics?',
                answer: [
                  `   Our Reports allow you to view the number of sub-affiliates you have
                                            recruited and what earnings you have gained from them.`,
                  `Please contact your affiliate manager if you have any other
                                            questions relating to this.`,
                ],
              },
            ],
          },
          {
            title: 'Marketing tools',
            qanda: [
              {
                question:
                  'Where can I find marketing tools for winmasters products?',
                answer: [
                  `Once your winmasters Affiliates account has been approved, you will
                                be able to log in and find a range of text links, banners and more
                                in the ‘Media Gallery’ section of our interface.`,
                ],
              },
              {
                question:
                  'Can I request a special marketing tool for my website?',
                answer: [
                  `Special, exclusive tools can be arranged. Contact your affiliate
                                manager to discuss the possibilities.`,
                ],
              },
              {
                question:
                  'Can I offer visitors to my website an incentive to sign up?',
                answer: [
                  `winmasters website offer a range of incentives to new players. These
                                bonuses vary according to the product you are promoting and the
                                market which you are targeting. To check the latest promotions for
                                the product and market you are targeting, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question: 'Do you offer bonus codes?',
                answer: [
                  `Yes, we can offer bonus codes for winmasters. Please contact your
                                affiliate manager to request one.`,
                ],
              },
            ],
          },
          {
            title: 'Earnings and Payments',
            qanda: [
              {
                question: 'What are the available payment methods?',
                answer: [
                  `winmasters offers a number of payment methods including bank
                                transfer and Skrill (formerly Moneybookers). Please make sure that
                                your payment options are kept up-to-date under My Account & Payment
                                Information.`,
                ],
              },
              {
                question: 'When is my commission paid?',
                answer: [
                  ` We pay our affiliates 45 – 60 days upon payment request with a
                                minimum payment of 100 Euros. If you haven’t reached that level then
                                your earnings will be carried over to the following month.`,
                ],
              },
              {
                question: 'Where can I see my earnings?',
                answer: [
                  `You can view your statistics at any time via the Reports > Earnings
                                Reports section in the winmasters Affiliates interface.`,
                ],
              },
              {
                question: 'Is there a limit in my earnings?',
                answer: [
                  `There is no limit! We offer competitive and flexible reward plans to
                                reflect the quantity and quality of the players which you send to
                                us. For further details, please contact your affiliate manager.`,
                ],
              },
              {
                question:
                  'Where can I see my commission status and other information?',
                answer: [
                  `As soon as you log into your affiliate account, your payment
                                information will be displayed on the main page. More detailed
                                information can be obtained by clicking onto ‘Reports’, ‘Earnings
                                Report’ or ‘Payment Report’.`,
                ],
              },
              {
                question: `Are Sub-affiliates earnings paid on the same date as my regular
                                payments?`,
                answer: [
                  `Yes, your sub-affiliate commission is paid together with your
                                regular affiliate earnings every month. You can view your
                                sub-affiliates under Reports > Earnings Reports > Sub-Affiliates.`,
                ],
              },
            ],
          },
        ],
      },
    },
    spanish: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Iniciar sesión',
          loginLink: 'https://partners.winmasters.com/en/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/en/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/en/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Inicio',
          aboutUs: 'Sobre nosotros',
          whatWeOffer: 'Products',
          testimonials: 'Testimonios',
          contact: 'Contáctenos',
          rewardTiers: 'Niveles de recompensas',
          faq: 'Preguntas Frecuentes',
          terms: 'Términos y Condiciones',
        },
      },
      footer: {
        sponsors: 'Golden Sponsor',
        socialMedia: 'Social Media',
        // socialLink: {
        //   facebook: '',
        //   instagram: '',
        //   youtube: '',
        // },
        copyright: `© ${currentYear} winmasters es una marca registrada. Reservados todos los derechos.`,
      },
      homePage: {
        hero: {
          title: ['Convierte a tu audiencia en'],
          typing: ['socios.', 'ingresos.', 'un negocio.'],
          cta: 'Registro',
        },
        steps: {
          title: 'Estás a 3 pasos de obtener tus primeros ingresos.',
          steps: [
            {
              iconUrl: '',
              title: 'Registro',
              p: 'Todo lo que tiene que hacer es llenar sus datos en la página de “Inscribirse” y enviar el formulario de registro. ¡Registrese ahora!',
              link: {
                text: ' Registro',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Sea Aprobado',
              p: 'Su aplicación será revisada por nuestro equipo de Afiliación, y si cumple con los requisitos, será aceptado.',
            },
            {
              iconUrl: '',
              title: 'Obtenga Ingresos',
              p: 'Una vez que se apruebe su solicitud, podrá comenzar a promocionar winmasters y obtener ingresos instantáneos.',
            },
          ],
        },
        aboutUs: {
          title: 'Sobre Nosotros',
          paragraphs: [
            'El equipo de Afiliados de winmasters tiene un conocimiento real del marketing de afiliación y tiene como objetivo proporcionar un apoyo profesional de primer nivel a sus afiliados.',
            'El equipo de winmasters está formado por personas con muchos años de conocimiento en la industria de los juegos en línea y el marketing de afiliación. Esta es una "apuesta segura" para todos los socios que deseen cooperar con winmasters.',
          ],
          subtitle: 'Principios de nuestro equipo',
          list: [
            'Programa de afiliados líder en la industria que genera ingresos',
            'Hasta un 50 % de comisión de reparto de ingresos en todos los productos winmasters',
            'Enfoque local para clientes y afiliados, con soporte en 4 idiomas diferentes',
            'Profundo conocimiento de cada mercado respectivo y sus necesidades',
            'Nuestro equipo le dará el mejor soporte y conocimiento para lograr sus metas de afiliado y maximizar sus ganancias.',
          ],
        },
        whatWeOffer: {
          title: 'Lo que ofrecemos',
          tabTitles: ['Sportsbook', 'Casino'],
          articles: [
            {
              for: 'sportsbook',
              titles: ['Probabilidades Competitivas', 'Apuestas en Vivo'],
              paragraphs: [
                [
                  'Los precios de winmasters se encuentran entre los más competitivos del mercado. También cubrimos una amplia gama de mercados, desde los mayores eventos globales hasta ligas locales y deportes menores en toda Europa y a nivel mundial. winmasters apunta a proveer la oferta global y local más interesante a todos los clientes.',
                ],
                [
                  'Las apuestas en vivo son de las opciones más populares para nuestros clientes y esa es la razón por la que ofrecemos más de 25.000 eventos al mes durante todo el año.',
                ],
              ],
            },
            {
              for: 'casino',
              titles: [
                '¿Por qué Casino winmasters?',
                'Bono de Bienvenida y Promociones',
              ],
              paragraphs: [
                [
                  'La diversión está garantizada. El Casino y vestíbulos de tragamonedas, más nuestro casino en vivo con dealers en vivo.  Ofrecemos regularmente un extenso portafolio de juegos, generosos bonos de bienvenida y promociones. Grandes jackpots, entre los mayores del mercado.',
                  'winmasters Casino ofrece los mejores juegos de su clase de los mejores proveedores como NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. Ofrecemos una experiencia de casino en línea de primer nivel con los juegos más nuevos del mercado y siguiendo sus últimas tendencias. Hay tres destinos de casino diferentes disponibles que crean el "mundo de casino" virtual ideal. ¡Vive la verdadera experiencia del casino en línea!',
                ],
                [
                  'El bono de bienvenida del Casino está estudiado para ser disfrutado por cualquier cliente de cualquier preferencia y gusto. Tiradas gratuitas, bonos de depósito, torneos a menudo se ofrecen a los clientes habituales.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Best Bookmakers',
            paragraph:
              '"Si está buscando una asociación lucrativa, no puede equivocarse con Winmasters Afiliados. El equipo es muy amable y servicial, siempre dispuesto a ayudarnos. ¡Creemos que tendremos una asociación fuerte y a largo plazo con ellos!"',
            linkUrl: 'https://bestbookmakers.co.uk/',
            linkText: 'bestbookmakers.co.uk',
          },
          {
            title: 'bookmakers',
            paragraph:
              '"winmasters está comenzando a dejar su huella en la industria del iGaming y es realmente un placer cooperar con una marca tan innovadora. Ofrecen una alta tasa de conversión y están disponibles para responder cualquier pregunta que pueda tener."',
            linkUrl: 'https://www.bookmakers.bet/',
            linkText: 'www.bookmakers.bet',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"Winmasters es una de las Empresas de apuestas de mayor crecimiento en el mercado Europeo. Recomendamos sin reservas trabajar con una marca tan profesional e innovadora."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
        ],
        contactUs: {
          title: 'Contáctenos',
          button: 'Enviar',
          fields: {
            name: 'Nombre',
            surname: 'Apellido',
            email: 'Correo Electrónico',
            message: 'Mensaje',
          },
        },
      },
      termsandconditions: {
        title: ['Términos y Condiciones'],

        languageSelectors: {
          selectCountry: 'Select the country',
          selectLanguage: 'Select the language',
          countries: {
            Greece: {
              country: 'Greece',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Romania: {
              country: 'Romania',
              languages: {
                english: 'English',
                native: 'Romanian',
              },
            },
            Cyprus: {
              country: 'Cyprus',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'English',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Niveles de Recompensa'],
        descriptions: [
          'Ingresos Netos**',
          'Participación de Ingresos Netos',
          'Impulso de Comisiones (Commission Booster)*',
          'Total de Ganancias',
        ],
        tabTitles: ['Nivel 1', 'Nivel 2', 'Nivel 3', 'Nivel 4', 'Nivel 5'],
        tierArticles: [
          {
            tier: 'Nivel 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Nivel 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Nivel 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Nivel 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Nivel 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '*¡Obtenga un impulso de comisión del 5% cuando nos envíe más de 40 jugadores activos por primera vez dentro de un mes calendario!',
        earnings: {
          title: 'Cuando se puede ganar',
          info: [
            {
              description: 'Comisión del Sub-afiliado 5 %',
              amount: '¡Sin límite!',
            },
            {
              description: 'CPA/Recompensas Hibridas €€€',
              amount: '¡Sin límite!',
            },
          ],
        },
        extras: {
          title: 'Plan de reparto de ingresos escalonado',
          sections: [
            {
              paragraphs: [
                'Cada mes calendario, usted ganara un porcentaje de los ingresos netos de todos los jugadores que ha referido a winmasters.',
                'El porcentaje de participación en los ingresos netos que se le pagará cada mes depende de dos criterios: los ingresos netos y los nuevos jugadores que depositan (consulte la sección Impulso de comisiones a continuación).',
                'A medida que los jugadores que recomiende generen ingresos netos, se le pagará un porcentaje de esto como comisión. Cuando tus jugadores referidos generen un total de 10.000€ o más en un mes natural, el porcentaje que recibirás aumentará del 25% al 30%. Cuando generen más de 50.000 €, su participación en los ingresos netos aumentará al 45%.',
                'Si el ingreso de sus jugadores es negativo en cualquier mes, éste será llevado al siguiente mes (esto menudo de llama remanente).',
                '**El ingreso neto se define como sigue: Ingresos Brutos menos Costo de Ventas (que comprende impuestos, tarifa de red, tarifas de transacciones, costos de bonos y primas).',
              ],
            },
            {
              title: 'Impulso de Comisiones (Commission Booster)',
              paragraphs: [
                'Recomiende a 40 o más jugadores activos por primera vez cada mes y obtenga una bonificación del 5% en su participación en los ingresos netos.',
              ],
            },
            {
              title: 'Comisión de Sub-afiliados',
              paragraphs: [
                'También puede ganar una comisión de sub-afiliado del 5% al reclutar a otro afiliado para el programa de afiliados de winmasters.',
                `La comisión se calcula sobre las recompensas de sus sub-afiliados y se suma a su comisión mensual.`,
              ],
            },
            {
              title: 'CPA/Hybrid Deals',
              paragraphs: [
                'Si está interesado en hablar sobre un costo por adquisición (CPA) o un plan de recompensa híbrido, comuníquese con su administrador de afiliados.',
              ],
            },
            {
              title: 'Opciones de Pago para Afiliados',
              paragraphs: ['Transferencia Bancaria y Skrill '],
            },
          ],
        },
        disclaimer2:
          'Tenga en cuenta que, en algunos casos, según el país en el que se encuentre, es posible que algunas de estas opciones no estén disponibles.',
      },
      faq: {
        title: [' Preguntas Frecuentes'],
        sections: [
          {
            title: 'General',
            qanda: [
              {
                question:
                  '¿Como funciona el programa de Afiliados de winmasters?',
                answer: [
                  `El programa de Afiliados de winmasters recompensa a terceras partes por referir clientes a la página web de winmasters.`,
                  `winmasters Afiliados envía tráfico a nuestros sitios via links rastreados, y cuando esos usuarios se registran como clientes, depositan y juegan con nosotros, recompensaremos a los Afiliados con una comisión.`,
                ],
              },
              {
                question: '¿Qué es un sub-afiliado?',
                answer: [
                  `Un sub-afiliado es un nuevo afiliado que usted refiere a affiliates.winmasters.com. Usted puede hacer esto al hacer que firmen via el link de nuevo afiliado que encontrará en su cuenta.`,
                  `Una vez que se hayan registrado, puede obtener ganancias adicionales por cada cliente que nos envíe su sub-afiliado. Puede encontrar su enlace de sub-afiliado en el resumen de su cuenta.`,
                ],
              },
            ],
          },
          {
            title: 'Mi Cuenta',
            qanda: [
              {
                question: '¿Dónde puedo revisar las estadísticas de mi cuenta?',
                answer: [
                  `Cuando usted inicia sesión en su cuenta de Afiliados de winmasters, usted es capaz de acceder a una amplia gama de reportes que detallan el tráfico que se nos ha enviado via su sitio, junto con cualquier transacción que se haya realizado.`,
                  `Si tuviera alguna pregunta con respecto a estos reportes, entonces utilice nuestro formulario de contacto o póngase en contacto directamente con su administrador afiliado.`,
                ],
              },
              {
                question: '¿Qué tan a menudo se actualizan mis estadísticas?',
                answer: [
                  `Los informes en la interfaz de Afiliados de winmasters se actualizan diariamente, generalmente a las 3 p. m. GMT/4 p. m. BST. En el caso que crea que sus estadísticas no se han actualizado o tenga alguna pregunta, comuníquese con su administrador de afiliados.`,
                ],
              },
              {
                question: '¿Cómo se vinculan los usuarios a mi cuenta?',
                answer: [
                  `Cuando el cliente hace click en un link o banner en su página web, su único código de rastreo, significa que el cliente solo se puede conectar a su cuenta.`,
                  `Para obtener informacion más detallada sobre cómo funciona esto, póngase en contacto con su administrador afiliado.`,
                ],
              },
              {
                question:
                  '¿Como puedo verificar el número de usuarios que he referido a winmasters?',
                answer: [
                  `Como afiliado, usted tiene acceso a reportes al cliente, el cual puede encontrar al iniciar sesión en su cuenta. Este puede darle informacion sobre los jugadores, pero sírvase estar informado que su informacion personal no deberá ser compartida debido a la Ley de Protección de Datos.`,
                ],
              },
              {
                question: '¿Existen países donde no puedo promover winmasters?',
                answer: [
                  `Los jugadores de los siguientes países no son aceptados en winmasters:`,
                  `Estados Unidos de América y Francia.`,
                ],
              },
              {
                question:
                  '¿Cómo puedo revisar las estadísticas de mis sub-afiliados?',
                answer: [
                  `Nuestros informes le permiten ver la cantidad de sub-afiliados que ha reclutado y las ganancias que ha obtenido de ellos.`,
                  `Póngase en contacto con su administrador de afiliados si tiene alguna otra pregunta relacionada con esto.`,
                ],
              },
            ],
          },
          {
            title: 'Herramientas de Marketing',
            qanda: [
              {
                question:
                  '¿Dónde puedo encontrar herramientas de marketing para los productos de winmasters?',
                answer: [
                  `Una vez que se haya aprobado su cuenta de afiliados de winmasters, podrá iniciar sesión y encontrar una variedad de enlaces de texto, pancartas y más en la sección "Galería de medios" de nuestra interfaz.`,
                ],
              },
              {
                question:
                  '¿Puedo solicitar una herramienta de marketing especial para mi página web?',
                answer: [
                  `Se pueden organizar herramientas especiales y exclusivas. Póngase en contacto con su administrador de afiliados para analizar las posibilidades.`,
                ],
              },
              {
                question:
                  '¿Puedo ofrecer a los visitantes de mi sitio web un incentivo para registrarse?',
                answer: [
                  `El sitio web de winmasters ofrece una variedad de incentivos para los nuevos jugadores. Estos bonos varían según el producto que esté promocionando y el mercado al que se dirija. Para verificar las últimas promociones para el producto y el mercado al que se dirige, comuníquese con su administrador de afiliados.`,
                ],
              },
              {
                question: '¿Ofrecen códigos de bono?',
                answer: [
                  `Si, Podemos ofrecer códigos de bono para winmasters. Sírvase ponerse en contacto con su administrador de afiliados para solicitar uno.`,
                ],
              },
            ],
          },
          {
            title: 'Ganancias y Pagos',
            qanda: [
              {
                question: '¿Cuáles son los Metodos de pago disponibles?',
                answer: [
                  `winmasters ofrece un numero de métodos de pago, incluyendo transferencias bancarias y Skrill (anteriormente Moneybookers). Sírvase asegurarse que sus opciones de pago se mantengan actualizadas bajo Mi Cuenta e Informacion de Pago.`,
                ],
              },
              {
                question: '¿Cuándo se pagará mi comisión?',
                answer: [
                  `Pagamos a nuestros afiliados entre 45 y 60 días después de la solicitud de pago con un pago mínimo de 100 euros. Si no ha alcanzado ese límite, sus ganancias se transferirán al mes siguiente.`,
                ],
              },
              {
                question: '¿Dónde puedo ver mis ganancias?',
                answer: [
                  `Usted puede ver sus estadísticas en cualquier momento via la sección Reportes > Reportes de Ganancias en la interfaz de Afiliados de winmasters.`,
                ],
              },
              {
                question: '¿Existe un límite en mis ganancias?',
                answer: [
                  `¡No hay límite! Ofrecemos planes de recompensas competitivos y flexibles para reflejar la cantidad y calidad de los jugadores que nos envía. Para recibir más detalles, sírvase ponerse en contacto con su administrador de afiliados.`,
                ],
              },
              {
                question:
                  '¿Puedo ver el estado de mi comisión y otra informacion?',
                answer: [
                  `Tan pronto como inicie sesión en su cuenta de afiliado, su información de pago se mostrará en la página principal. Se puede obtener información más detallada haciendo clic en 'Informes', 'Informe de ganancias' o 'Informe de pago'.`,
                ],
              },
              {
                question: `¿Se pagan las ganancias de los Sub-afiliados en la misma fecha que mis pagos regulares?`,
                answer: [
                  `Sí, su comisión de sub-afiliado se paga junto con sus ganancias regulares de afiliado cada mes. Puede ver sus sub-afiliados en Informes > Informes de ganancias > Sub-afiliados.`,
                ],
              },
            ],
          },
        ],
      },
    },
  },
  greece: {
    native: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Είσοδος',
          loginLink: 'https://partners.winmasters.com/el/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/el/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/el/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Κεντρική',
          aboutUs: 'Σχετικά με εμάς',
          whatWeOffer: 'Προϊόντα',
          testimonials: 'Testimonials',
          contact: 'Επικοινωνία',
          rewardTiers: 'Προγράμματα Ανταμοιβής',
          faq: 'Συχνές ερωτήσεις',
          terms: 'Όροι & Προϋποθέσεις',
        },
      },
      footer: {
        sponsors: 'Μεγάλος Χορηγός',
        socialMedia: 'Social Media',
        socialLink: {
          facebook: 'https://www.facebook.com/winmasters.greece',
          instagram: 'https://www.instagram.com/winmasters.gr/',
          youtube: 'https://www.youtube.com/channel/UCdiZHVzKgia9R_T80zeoupw',
        },
        copyright: `© ${currentYear} Η winmasters είναι ένα κατοχυρωμένο εμπορικό σήμα. Όλα τα δικαιώματα διατηρούνται.`,
      },
      homePage: {
        hero: {
          title: ['Μετατρέψτε το κοινό σας σε'],
          typing: ['συνεργάτες.', 'εισόδημα.', 'επιχείρηση.'],
          cta: 'Εγγραφή',
        },
        steps: {
          title:
            'Βρίσκεστε 3 βήματα μακριά από την απόκτηση των πρώτων εσόδων σας',
          steps: [
            {
              iconUrl: '',
              title: 'Εγγραφή',
              p: 'Το μόνο που έχετε να κάνετε είναι να συμπληρώσετε τα στοιχεία σας στη σελίδα "Εγγραφή" και να υποβάλετε τη φόρμα εγγραφής. ',
              link: {
                text: 'Εγγραφή τώρα!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Πάρτε έγκριση',
              p: 'Η αίτηση σας θα επαληθευθεί απο την ομάδα Affiliate της winmasters και εφόσoν τηρεί τα κριτήρια μας θα γίνει και αποδεκτή.',
            },
            {
              iconUrl: '',
              title: 'Αποκτήστε έσοδα',
              p: 'Εφόσoν η αίτηση σας έχει γίνει αποδεκτή θα μπορέσετε να ξεκινήσετε την προώθηση της winmasters και να έχετε άμεσα έσοδα.',
            },
          ],
        },
        aboutUs: {
          title: 'Σχετικά με εμάς',
          paragraphs: [
            'Η ομάδα Εξυπηρέτησης Συνεργατών της winmasters έχει μία πραγματική κατανόηση του μάρκετινγκ συνεργατών και στοχεύει στο να παρέχει ένα κορυφαίο επίπεδο υποστήριξης στους συνεργάτες της.',
            'Η ομάδα της  winmasters αποτελείται από άτομα με πολύχρονη γνώση στον κλάδο των διαδικτυακών τυχερών παιχνιδιών και το μάρκετινγκ συνεργατών. Αυτό είναι ένα «σίγουρο στοίχημα» για κάθε μέλος που θα ήθελε να συνεργαστεί με τη winmasters.',
          ],
          subtitle: 'Οι αρχές της ομάδας μας',
          list: [
            'Κορυφαίο πρόγραμμα affiliate για τη δημιουργία εσόδων.',
            'Το μερίδιο των εσόδων από προμήθεια σε όλα τα προϊόντα winmasters φτάνει έως και το 50%',
            'Για πελάτες και συνεργάτες η προσέγγιση σε τοπικό επίπεδο, υποστηρίζεται από 4 διαφορετικές γλώσσες',
            'Βαθιά γνώση της κάθε αντίστοιχης αγοράς και των αναγκών της',
            'Η ομάδα μας θα σας παρέχει την καλύτερη δυνατή υποστήριξη και τη γνώση ώστε να επιτύχετε τους στόχους συνεργατών και να μεγιστοποιήσετε το κέρδος σας.',
          ],
        },
        whatWeOffer: {
          title: 'Τι προσφέρουμε',
          tabTitles: ['Αθλητικό στοίχημα', 'Καζίνο'],
          articles: [
            {
              for: 'sportsbook',
              titles: [
                'Ανταγωντιστικές αποδόσεις',
                'Live Αθλητικό στοίχημα',
                'Στοίχημα μέσω κινητού',
                'Μπόνους καλωσορίσματος και Προσφορές',
              ],
              paragraphs: [
                [
                  'Οι αποδόσεις της winmasters είναι απο τις πιο ανταγωνιστικές της αγοράς. Καλύπτουμε μια ευρεία κλίμακα αγορών απο τις μεγαλύτερες παγκόσμιες διοργανώσεις μέχρι τα τοπικά πρωταθλήματα και μικρότερες κατηγορίες σε Ευρώπη και ολόκληρο τον κόσμο. Η winmasters στοχεύει να φέρει την πιο ενδιαφέρουσα διεθνή και τοπική προσφορά σε όλους τους πελάτες της.',
                ],
                [
                  `Το Live Αθλητικό στοίχημα είναι απο τα πιο δημοφιλή παιχνίδια των παικτών μας και γι'αυτο το λόγο προσφέρουμε περισσότερα απο 25.000 γεγονότα κάθε μήνα όλο τον χρόνο.`,
                ],
                [
                  `Η ιδέα του στοιχηματισμού καθ’ όδον γίνεται όλο και πιο δημοφιλής. Η σχεδίαση της ιστοσελίδας μας ανταποκρίνεται σε αυτήν την ιδέα και επιτρέπει στους χρήστες μας να χαρούν τις υπηρεσίες μας από οποιαδήποτε κινητή συσκευή.`,
                ],
                [
                  `Η winmasters προσφέρει μπόνους καλωσορίσματος προσαρμοσμένα σε κάθε αγορά, τα οποία κυμαίνονται από γενναιόδωρα μπόνους ανάλογα της κατάθεσης έως το ρίσκο των δωρεάν στοιχημάτων. Νοιαζόμαστε για τους πελάτες μας και τους προσεγγίζουμε με τον καλύτερο δυνατό τρόπο. Πολλές εκπλήξεις… `,
                ],
              ],
            },
            {
              for: 'casino',
              titles: [
                'Γιατί να προτιμήσω το Καζίνο στη winmasters;',
                'Μπόνους καλωσορίσματος και Προσφορές',
              ],
              paragraphs: [
                [
                  'Εγγυημένη διασκέδαση. Με λόμπυ Καζίνο και slots συν το live Καζίνο με αληθινούς ντίλερ προσφέρουμε μία ευρεία γκάμα παιχνιδιών, γενναιόδωρα μπόνους καλωσορίσματος και προσφορές σε τακτική βάση. Μεγάλα τζακ ποτ τα οποία είναι από τα υψηλότερα που μπορείτε να βρείτε στην αγορά.',
                  'H winmasters προσφέρει τα καλύτερα παιχνίδια Καζίνο που παράγονται από κορυφαίους προμηθευτές όπως οι NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. Προσφέρουμε μία κορυφαίου επιπέδου εμπειρία στο online Καζίνο με τα πιο καινούργια παιχνίδια στην αγορά και τα οποία ακολουθούν τις τελευταίες τάσεις. Τρεις διαφορετικοί προορισμοί καζίνο είναι διαθέσιμοι, δημιουργώντας τον ιδανικό εικονικό «κόσμο του καζίνο». Ζήστε online την πραγματική εμπειρία καζίνο!',
                ],
                [
                  'Το μπόνους καλωσορίσματος στο Καζίνο είναι μελετημένο για να το χαρεί κάθε πελάτης οποιασδήποτε εθνικότητας, με κάθε προτίμηση και γούστο. Δωρεάν περιστροφές (Free spins), μπόνους κατάθεσης, τουρνουά προσφέρονται συχνά στους τακτικούς πελάτες μας.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Betarades.gr',
            paragraph:
              '"Ως κορυφαίο ενημερωτικό σάιτ για το στοίχημα και το καζίνο δε θα μπορούσαμε να μη συνεργαζόμαστε με έναν εκ των κορυφαίων παρόχων. Η Winmasters αποτελεί μία αξιόπιστη και ασφαλής επιλογή για τους παίκτες του στοιχήματος και του casino και ως ειδικοί τη συστήνουμε ανεπιφύλακτα."',
            linkUrl: 'http://www.betarades.gr',
            linkText: 'www.betarades.gr',
          },
          {
            title: 'Casinoslot.gr',
            paragraph:
              '"Ένας από τους λίγους premium συνεργάτες μας εδώ και χρόνια έχει καταφέρει να κερδίσει την εμπιστοσύνη του κοινού μας, που είναι απαιτητικό και έμπειρο. Το καζίνο της Winmasters αποτελεί μία κορυφαία επιλογή συνδυάζοντας τη μεγάλη γκάμα παιχνιδιών και το εξαιρετικό περιβάλλον πλοήγησης με την αξιοπιστία και την ασφάλεια συναλλαγών μαζί με τις άμεσες πληρωμές. Αδιαμφισβήτητη επιλογή."',
            linkUrl: 'https://www.casinoslot.gr/',
            linkText: 'www.casinoslot.gr',
          },
          {
            title: 'Apati',
            paragraph:
              '"Η συνεργασία με την winmasters ήταν χαρά μας από την αρχή. Ένας πολύ ασφαλής συνεργάτης για affiliates! Συνιστούμε σε όλους μας τους φίλους affiliates να αρχίσουν να προωθούν το brand winmasters σήμερα."',
            linkUrl: 'https://www.apati.info/',
            linkText: 'www.apati.info ',
          },
          {
            title: 'Kalitera Casino Online',
            paragraph:
              '"Αξιόπιστη και επαγγελματική ομάδα για affiliates! Είμαστε πολύ ικανοποιημένοι με τη συνεργασία. Η winmasters είναι μια εταιρία την οποία συνιστούμε για συνεργασία δίχως κανέναν ενδοιασμό."',
            linkUrl: 'https://www.kaliteracasinoonline.com/',
            linkText: 'www.kaliteracasinoonline.com',
          },
        ],
        contactUs: {
          title: 'Επικοινωνία',
          button: 'Αποστολή',
          fields: {
            name: 'Όνομα',
            surname: 'Επώνυμο',
            email: 'Email',
            message: 'Μήνυμα',
          },
        },
      },
      termsandconditions: {
        title: ['Όροι & Προϋποθέσεις'],

        languageSelectors: {
          selectCountry: 'Επιλέξτε τη χώρα',
          selectLanguage: 'Επιλέξτε τη γλώσσα',
          countries: {
            Greece: {
              country: 'Ελλάδα',
              languages: {
                english: 'Αγγλικά',
                native: 'Ελληνικά',
              },
            },
            Romania: {
              country: 'Ρουμανία',
              languages: {
                english: 'Αγγλικά',
                native: 'Ρουμάνικα',
              },
            },
            Cyprus: {
              country: 'Κύπρος',
              languages: {
                english: 'Αγγλικά',
                native: 'Ελληνικά',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'Αγγλικά',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Προγράμματα Ανταμοιβής'],
        descriptions: [
          'Καθαρά Έσοδα**',
          'Καθαρό Μερίδιο Εσόδων',
          'Ενισχυτική Προμήθεια*',
          'Συνολικά Κέρδη',
        ],
        tabTitles: ['Επίπ. 1', 'Επίπ. 2', 'Επίπ. 3', 'Επίπ. 4', 'Επίπ. 5'],
        tierArticles: [
          {
            tier: 'Επίπεδο 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Επίπεδο 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Επίπεδο 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Επίπεδο 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Επίπεδο 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Λάβετε μία Ενισχυτική Προμήθεια +5% όταν θα μας στείλετε 40+ Παίκτες που θα πραγματοποιήσουν Νέα Κατάθεση εντός ενός ημερολογιακού μήνα!',
        earnings: {
          title: 'Πόσα μπορείτε να κερδίσετε',
          info: [
            {
              description: 'Προμήθεια Υπό-Συνεργάτη 5 %',
              amount: 'Χωρίς όριο!',
            },
            {
              description: 'CPA/Υβριδικές Ανταμοιβές €€€',
              amount: 'Χωρίς όριο!',
            },
          ],
        },
        extras: {
          title: 'Πρόγραμμα Κλιμακωτής Κατανομής Μεριδίων',
          sections: [
            {
              paragraphs: [
                'Κάθε ημερολογιακό μήνα, θα κερδίζετε ένα ποσοστό των καθαρών εσόδων από όλους τους παίκτες που έχετε παραπέμψει στη winmasters.',
                'Το ποσοστό του μεριδίου των καθαρών εσόδων που θα πρέπει να καταβληθεί κάθε μήνα εξαρτάται από δύο κριτήρια: τα καθαρά έσοδα και τους νέους παίκτες που θα πραγματοποιήσουν κατάθεση (δείτε την παράγραφο Ενισχυτική Προμήθεια παρακάτω).',
                'Καθώς οι παίκτες που παραπέμψατε θα παράγουν καθαρά έσοδα, θα σας δοθεί ένα ποσοστό από αυτό ως προμήθεια. Όταν οι παίκτες που παραπέμψατε παράγουν συνολικά € 10.000 ή περισσότερο εντός ενός ημερολογιακού μήνα, το ποσοστό που θα λάβετε θα αυξηθεί από 25% έως 30%. Όταν θα παράγουν πάνω από € 50,000, το καθαρό μερίδιο των εσόδων σας θα αυξηθεί στο 45%.',
                'Εάν τα έσοδα από τους παίκτες σας είναι αρνητικά σε οποιοδήποτε μήνα, θα μεταφέρονται στον επόμενο μήνα (αυτό συνήθως ονομάζεται «αρνητική μεταφορά»).',
                '** Τα Καθαρά Έσοδα ορίζονται ως εξής: Ακαθάριστα Έσοδα μείον Κόστος πωλήσεων (συμπεριλαμβανομένων των φόρων, τελών του δικτύου, τα έξοδα συναλλαγών, τα κόστη των μπόνους και τις πριμοδοτήσεις).',
              ],
            },
            {
              title: 'Ενισχυτική Προμήθεια',
              paragraphs: [
                'Κάθε μήνα συστήστε 40 ή περισσότερους νέους παίκτες που θα πραγματοποιήσουν κατάθεση και κερδίστε ένα μπόνους 5% επί του μεριδίου των καθαρών εσόδων σας.',
              ],
            },
            {
              title: 'Προμήθεια Υπό-Συνεργάτη ',
              paragraphs: [
                'Μπορείτε επίσης να κερδίσετε μια προμήθεια υπο-συνεργάτη του 5% με το να φέρετε άλλον ένα συνεργάτη στο  πρόγραμμα Συνεργατών της winmasters.',
                `Η προμήθεια υπολογίζεται επί των ανταμοιβών των υπο-συνεργατών σας και προστίθεται στη μηνιαία προμήθεια σας.`,
              ],
            },
            {
              title: 'CPA/Υβριδικές Συμφωνίες',
              paragraphs: [
                'Εάν σας ενδιαφέρει να συζητήσουμε ένα κόστος-ανά-απόκτηση πελάτη (CPA) ή ένα υβριδικό πρόγραμμα ανταμοιβής, παρακαλούμε επικοινωνήστε με τον υπεύθυνο συνεργατών του λογαριασμού σας.',
              ],
            },
            {
              title: 'Επιλογές Πληρωμής Συνεργάτη',
              paragraphs: [
                'Τραπεζικό έμβασμα και Skrill (πρώην Moneybookers).',
              ],
            },
          ],
        },
        disclaimer2:
          'Παρακαλώ σημειώστε ότι, σε ορισμένες περιπτώσεις, ανάλογα με τη χώρα όπου βρίσκεστε, μερικές από αυτές τις επιλογές πιθανόν να μην είναι διαθέσιμες.',
      },
      faq: {
        title: [' Συχνές ερωτήσεις'],
        sections: [
          {
            title: 'Γενικές',
            qanda: [
              {
                question: 'Τι είναι το Πρόγραμμα Συνεργατών της winmasters;',
                answer: [
                  `Το Πρόγραμμα Συνεργατών της winmasters ανταμείβει τρίτα συμβαλλόμενα μέρη για την παραπομπή πελατών στην ιστοσελίδα της winmasters.`,
                  `Οι Συνεργάτες της winmasters στέλνουν κίνηση στις ιστοσελίδες μας μέσω παρακολουθούμενων συνδέσμων, και όταν αυτοί οι χρήστες προχωρούν στην εγγραφή τους ως πελάτες, καταθέσουν και παίξουν σε εμάς, εμείς ανταμείβουμε τους συνεργάτες με μία προμήθεια.`,
                ],
              },
              {
                question: 'Τι είναι ο Υπο-Συνεργάτης;',
                answer: [
                  `Ο υπο-συνεργάτης είναι ένας νέος συνεργάτης τον οποίο εσείς παραπέμψατε στην affiliates.winmasters.com. Μπορείτε να το πράξετε αυτό με το να τους βάλετε να εγγραφούν μέσω του συνδέσμου νέου συνεργάτη το οποίο θα βρείτε στο λογαριασμό σας.`,
                  `Από τη στιγμή που θα εγγραφούν, μπορείτε να επιτύχετε επιπλέον κέρδη για κάθε πελάτη που ο υπο-συνεργάτης θα στέλνει σε εμάς. Μπορείτε να βρείτε το Σύνδεσμο Υπο-Συνεργάτη στη σύνοψη του λογαριασμού σας.`,
                ],
              },
            ],
          },
          {
            title: 'Ο Λογαριασμός μου',
            qanda: [
              {
                question:
                  'Πού μπορώ να ελέγξω τα στατιστικά του λογαριασμού μου;',
                answer: [
                  `Όταν συνδεθείτε στο λογαριασμό Συνεργατών της winmasters θα είστε σε θέση να έχετε πρόσβαση σε μία ευρεία γκάμα αναφορών που περιγράφουν λεπτομερώς την κίνηση που μας έχει αποσταλεί μέσω της ιστοσελίδας σας μαζί με οποιεσδήποτε συναλλαγές έχουν πραγματοποιηθεί.`,
                  `Εάν έχετε οποιεσδήποτε ερωτήσεις σχετικά με αυτές τις αναφορές, τότε παρακαλούμε να χρησιμοποιήσετε τη φόρμα επικοινωνίας μας ή να έρθετε άμεσα σε επαφή με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question: 'Πόσο συχνά ενημερώνονται τα στατιστικά μου;',
                answer: [
                  `Οι αναφορές στη διασύνδεση Συνεργατών της winmasters ενημερώνονται καθημερινά, συνήθως μέχρι τις 3μμ GMT / 4μμ BST. Σε περίπτωση που πιστεύετε ότι τα στατιστικά σας δεν έχουν ενημερωθεί ή έχετε οποιεσδήποτε απορίες, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question: 'Πώς οι χρήστες συνδέονται με το λογαριασμό μου;',
                answer: [
                  `Όταν ένας πελάτης κάνει κλικ μέσα από ένα σύνδεσμο ή banner στην ιστοσελίδα σας, ο μοναδικός κώδικας παρακολούθησης σας, σημαίνει ότι ο πελάτης μπορεί να συνδεθεί μόνο με το λογαριασμό σας.`,
                  `Για πιο λεπτομερείς πληροφορίες σχετικά με το πώς λειτουργεί αυτή η διαδικασία, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question:
                  'Πώς μπορώ να ελέγξω τον αριθμό των χρηστών που έχω παραπέμψει στη winmasters;',
                answer: [
                  `Ως συνεργάτης έχετε πρόσβαση στην αναφορά του πελάτη, την οποία μπορείτε να βρείτε όταν συνδεθείτε στο λογαριασμό σας. Αυτή θα σας δώσει πληροφορίες σχετικά με τους παίκτες, αλλά πρέπει να γνωρίζετε ότι τα προσωπικά στοιχεία τους δεν θα κοινοποιηθούν, λόγω του νόμου περί Προστασίας Προσωπικών Δεδομένων.`,
                ],
              },
              {
                question:
                  'Υπάρχουν χώρες στις οποίες εκεί δεν μπορώ να προωθήσω τη winmasters;',
                answer: [
                  `Παίκτες από τις ακόλουθες επικράτειες δεν γίνονται αποδεκτοί στη winmasters:`,
                  `Ηνωμένες Πολιτείες της Αμερικής και Γαλλία.`,
                ],
              },
              {
                question:
                  'Πως μπορώ να ελέγξω τα στατιστικά των υπο-συνεργατών μου;',
                answer: [
                  `Οι Αναφορές μας σας επιτρέπουν να δείτε τον αριθμό των υπο-συνεργατών που έχετε προσελκύσει και τι κέρδη έχετε αποκομίσει από αυτούς.`,
                  `Παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας, αν έχετε οποιεσδήποτε άλλες ερωτήσεις σχετικά με αυτό.`,
                ],
              },
            ],
          },
          {
            title: 'Εργαλεία Μάρκετινγκ',
            qanda: [
              {
                question:
                  'Πού μπορώ να βρω εργαλεία μάρκετινγκ για τα προϊόντα της winmasters;',
                answer: [
                  `Από τη στιγμή που ο λογαριασμός σας Συνεργάτη στη winmasters έχει εγκριθεί, θα είστε σε θέση να συνδεθείτε και να βρείτε μια σειρά από συνδέσμους κειμένων, banners και περισσότερα στην ενότητα "Πολυμέσα"/(Media Gallery) της διασύνδεσης μας.`,
                ],
              },
              {
                question:
                  'Μπορώ να ζητήσω ένα ειδικό εργαλείο μάρκετινγκ για την ιστοσελίδα μου;',
                answer: [
                  `Ειδικά, αποκλειστικά εργαλεία μπορούν να κανονιστούν. Επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας για να συζητήσετε τις δυνατότητες.`,
                ],
              },
              {
                question:
                  'Μπορώ να προσφέρω στους επισκέπτες της ιστοσελίδας μου, ένα κίνητρο για να εγγραφούν;',
                answer: [
                  `Η ιστοσελίδα της winmasters προσφέρει μια σειρά από κίνητρα για νέους παίκτες. Αυτά τα μπόνους ποικίλουν ανάλογα με το προϊόν που προωθείτε και την αγορά στην οποία στοχεύετε. Για να ελέγξετε τις τελευταίες προωθητικές ενέργειες για το προϊόν και την αγορά όπου στοχεύετε, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question: 'Προσφέρετε κωδικούς μπόνους;',
                answer: [
                  `Ναι, μπορούμε να προσφέρουμε κωδικούς μπόνους για τη winmasters. Παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας για να αιτηθείτε μία προωθητική ενέργεια.`,
                ],
              },
            ],
          },
          {
            title: 'Κέρδη και Πληρωμές',
            qanda: [
              {
                question: 'Ποιες είναι οι διαθέσιμες μέθοδοι πληρωμής;',
                answer: [
                  `Η winmasters προσφέρει μια σειρά από μεθόδους πληρωμής συμπεριλαμβανομένων των τραπεζικών εμβασμάτων και της Skrill (πρώην Moneybookers). Παρακαλούμε βεβαιωθείτε ότι οι επιλογές πληρωμής σας είναι ενημερωμένες στο «Ο λογαριασμός μου> Πληροφορίες Πληρωμής».`,
                ],
              },
              {
                question: 'Πότε καταβάλλεται η προμήθειά μου;',
                answer: [
                  `Πληρώνουμε τους Συνεργάτες μας 45 - 60 ημερών από την αίτηση πληρωμής, με ελάχιστη πληρωμή το ποσό των 100 ευρώ. Αν δεν έχετε φτάσει σε αυτό το επίπεδο, τότε τα κέρδη σας θα μεταφερθούν στον επόμενο μήνα.`,
                ],
              },
              {
                question: 'Που μπορώ να δω τα κέρδη μου;',
                answer: [
                  `Μπορείτε να δείτε τα στατιστικά σας ανά πάσα στιγμή μέσω της ενότητας «Αναφορές > Αναφορές Κερδών» στη διασύνδεση Συνεργατών της winmasters.`,
                ],
              },
              {
                question: 'Υπάρχει κάποιο όριο στα κέρδη μου;',
                answer: [
                  `Δεν υπάρχει όριο! Προσφέρουμε ανταγωνιστική και ευέλικτη ανταμοιβή η οποία έχει σχεδιασθεί για να αντικατοπτρίζει την ποσότητα και την ποιότητα των παικτών που μας στέλνετε. Για περισσότερες πληροφορίες, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question:
                  'Που μπορώ να δω την κατάσταση της προμήθειάς μου και άλλες πληροφορίες;',
                answer: [
                  `Μόλις συνδεθείτε στο λογαριασμό σας Συνεργάτη, τα στοιχεία της πληρωμής σας θα εμφανισθούν στην κύρια σελίδα. Μπορείτε να λάβετε πιο λεπτομερείς πληροφορίες κάνοντας κλικ στο «Αναφορές», «Αναφορές Κερδών» ή «Αναφορά Πληρωμών».`,
                ],
              },
              {
                question: `Τα κέρδη των υπο-συνεργατών καταβάλλονται την ίδια ημερομηνία με τις τακτικές πληρωμές μου;`,
                answer: [
                  `Ναι, οι προμήθειες από τους υπο-συνεργάτες σας καταβάλλονται κάθε μήνα από κοινού με τα τακτικά κέρδη συνεργάτη. Μπορείτε να δείτε τους υπο-συνεργάτες σας στην ενότητα «Αναφορές > Κέρδη Αναφορών > Υπό-Συνεργάτες».`,
                ],
              },
            ],
          },
        ],
      },
    },
    english: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Login',
          loginLink: 'https://partners.winmasters.com/en/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/en/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/en/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Home',
          aboutUs: 'About us',
          whatWeOffer: 'Products',
          testimonials: 'Testimonials',
          contact: 'Contact us',
          rewardTiers: 'Reward tiers',
          faq: 'FAQ',
          terms: 'Terms & Conditions',
        },
      },
      footer: {
        sponsors: 'Golden Sponsor',
        socialMedia: 'Social Media',
        socialLink: {
          facebook: 'https://www.facebook.com/winmasters.greece',
          instagram: 'https://www.instagram.com/winmasters.gr/',
          youtube: 'https://www.youtube.com/channel/UCdiZHVzKgia9R_T80zeoupw',
        },
        copyright: `© ${currentYear} winmasters is a registered trademark. All rights reserved.`,
      },
      homePage: {
        hero: {
          title: ['Turn your audience into'],
          typing: ['partners.', 'income.', 'a business.'],
          cta: 'Register',
        },
        steps: {
          title: 'You are 3 steps away from earning your first revenue',
          steps: [
            {
              iconUrl: '',
              title: 'Register',
              p: 'All you have to do is fill in your details in the “Sign up” page and submit the registration form.',
              link: {
                text: ' Register now!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Get Approved',
              p: 'Your application will be reviewed by our Affiliate Team and if you meet the requirements you will be accepted.',
            },
            {
              iconUrl: '',
              title: 'Get Revenue',
              p: 'After your application is approved you will be able to start promoting winmasters and get instant revenue.',
            },
          ],
        },
        aboutUs: {
          title: 'About us',
          paragraphs: [
            'winmasters Affiliates team has a real understanding of affiliate marketing and aims to provide a top level professional support to its affiliates.',
            'winmasters team consists of people with many years of knowledge in the online gaming industry and affiliate marketing. This is a “sure bet” for every partner that would like to cooperate with winmasters.',
          ],
          subtitle: 'Our team’s principles',
          list: [
            'Industry-leading revenue generating affiliate program',
            'Up to 50% revenue share commission across all winmasters products',
            'Local approach for customers and affiliates, with support in 4 different languages',
            'Deep knowledge of each respective market and its needs',
            'Our team will provide you with the best support and knowledge in order to achieve your affiliate goals and maximize your profit.',
          ],
        },
        whatWeOffer: {
          title: 'What we offer',
          tabTitles: ['Sportsbook', 'Casino'],
          articles: [
            {
              for: 'sportsbook',
              titles: ['Competitive Odds', 'Live Betting'],
              paragraphs: [
                [
                  'winmasters prices are amongst the most competitive in the market. We also cover a wide range of markets, ranging from the biggest global events to local leagues and minor sports across Europe and worldwide. winmasters aims to provide the most interesting global and local offering to all customers.',
                ],
                [
                  'Live betting is one of the most popular options for our customers and that’s why we offer more than 25.000 events per month all over the year.',
                ],
              ],
            },
            {
              for: 'casino',
              titles: ['Why winmasters Casino?', 'Welcome Bonus & Promotions'],
              paragraphs: [
                [
                  'Fun is guaranteed. Casino and slots lobbies, plus our live Casino with live dealers we offer an extensive portfolio of games, generous welcome bonuses and promotions on a regular basis. Great jackpots amongst the higher you can find around.',
                  'winmasters Casino offers the best of breed games from the top providers such as NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. We are offering a top level online Casino experience with the newest games in the market and following its latest trends. Three different casino destinations are available creating the ideal virtual “casino world”. Live the real casino experience online!',
                ],
                [
                  'Casino welcome bonus is studied to be enjoyed from any customer of any nationality of any preference and taste. Free spins, deposit bonus, tournaments are often offered to the regulars.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Best Bookmakers',
            paragraph:
              '"If you’re looking for a lucrative partnership, you can’t go wrong with winmasters Affiliates. The team is very friendly and helpful, always ready to help us. We believe we will have strong and long-term partnership with them!"',
            linkUrl: 'https://bestbookmakers.co.uk/',
            linkText: 'bestbookmakers.co.uk',
          },
          {
            title: 'bookmakers',
            paragraph:
              '"winmasters are starting to leave their mark on the iGaming industry and it is truly a pleasure cooperating with such an innovating brand. They offer a high conversion rate and are readily available to answer any question you may have."',
            linkUrl: 'https://www.bookmakers.bet/',
            linkText: 'www.bookmakers.bet',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"Winmasters is one of the fastest growing betting companies in the European market. We unreservedly recommend working with such a professional and innovative brand."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
        ],
        contactUs: {
          title: 'Contact us',
          button: 'Send',
          fields: {
            name: 'Name',
            surname: 'Surname',
            email: 'Email',
            message: 'Message',
          },
        },
      },
      termsandconditions: {
        title: ['Terms and Conditions'],

        languageSelectors: {
          selectCountry: 'Select the country',
          selectLanguage: 'Select the language',
          countries: {
            Greece: {
              country: 'Greece',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Romania: {
              country: 'Romania',
              languages: {
                english: 'English',
                native: 'Romanian',
              },
            },
            Cyprus: {
              country: 'Cyprus',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'English',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Reward Tiers'],
        descriptions: [
          'Net Revenue**',
          'Net Revenue Share',
          'Commission Booster*',
          'Earnings total',
        ],
        tabTitles: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
        tierArticles: [
          {
            tier: 'Tier 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Tier 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Tier 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Tier 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Tier 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Get a 5% Commission Booster when you send us 40+ First Time Active Players within a calendar month!',
        earnings: {
          title: 'How much you can earn',
          info: [
            {
              description: 'Sub-Affiliate Commission 5 %',
              amount: 'No limit!',
            },
            {
              description: 'CPA/Hybrid Rewards €€€',
              amount: 'No limit!',
            },
          ],
        },
        extras: {
          title: 'Tiered Revenue Share Plan',
          sections: [
            {
              paragraphs: [
                'Each calendar month, you will earn a percentage of the net revenue from all the players you have referred to winmasters.',
                'The percentage of net revenue share which you will be paid each month depends on two criteria: net revenue and new depositing players (see Commission Booster section below).',
                'As the players you refer generate net revenue, you are paid a percentage of this as a commission. When your referred players generate a total of €10,000 or more within a calendar month, the percentage which you receive will increase from 25% to 30%. When they generate more than €50,000, your net revenue share will increase to 45%.',
                'If the revenue from your players is negative in any month it will be carried over to the following month (this is often called negative carryover).',
                '**Net Revenue is defined as follows: Gross Revenue less Cost of Sales (comprising taxes, network fees, transaction fees, bonus costs and premiums).',
              ],
            },
            {
              title: 'Commission Booster',
              paragraphs: [
                'Refer 40 or more First Time Active players each month and get a 5% bonus on your net revenue share.',
              ],
            },
            {
              title: 'Sub-Affiliate Commission',
              paragraphs: [
                'You can also earn a sub-affiliate commission of 5% by recruiting another affiliate to the winmasters Affiliates program.',
                `The commission is calculated on your sub-affiliates' rewards and added to your monthly commission.`,
              ],
            },
            {
              title: 'CPA/Hybrid Deals',
              paragraphs: [
                'If you are interested in discussing a cost-per-acquisition (CPA) or hybrid reward plan, please contact your affiliate manager.',
              ],
            },
            {
              title: 'Affiliate Payment Options',
              paragraphs: [
                'Bank transfer and Skrill (formerly Moneybookers). ',
              ],
            },
          ],
        },
        disclaimer2:
          'Please note that in some cases depending on the country that you are based in, some of these options may not be available.',
      },
      faq: {
        title: [' Frequently Asked Questions'],
        sections: [
          {
            title: 'General',
            qanda: [
              {
                question: 'How does the winmasters Affiliate program work?',
                answer: [
                  `The winmasters Affiliate program rewards third parties for referring
                                customers to winmasters website.`,
                  `winmasters Affiliates send traffic to our sites via tracked links,
                                and when those users go on to register as customers, deposit and
                                play with us, we reward the affiliates with a commission.`,
                ],
              },
              {
                question: 'What is a sub-affiliate?',
                answer: [
                  `    A sub-affiliate is a new affiliate which you refer to
                                affiliates.winmasters.com. You can do this by getting them to sign
                                up via the new affiliate link which you will find in your account.`,
                  ` Once they have signed up, you can achieve additional earnings for
                                every customer that your sub-affiliate sends to us. You can find
                                your Sub-Affiliate Link under your account summary.`,
                ],
              },
            ],
          },
          {
            title: 'My Account',
            qanda: [
              {
                question: 'Where can I check my account statistics?',
                answer: [
                  `       When you log in to your winmasters Affiliates account, you will be
                                able to access a wide range of reports that detail the traffic that
                                has been sent to us via your site together with any transactions
                                that have been carried out.`,
                  `  If you have any questions regarding these reports then please use
                                our contact form or get in touch with your affiliate manager
                                directly.`,
                ],
              },
              {
                question: 'How often are my statistics updated?',
                answer: [
                  `  The reports in the winmasters Affiliates interface are updated
                            daily, usually by 3pm GMT/4pm BST. In the case that you believe that
                            your statistics have not been updated or you have any questions,
                            please contact your affiliate manager.`,
                ],
              },
              {
                question: 'How are the users linked to my account?',
                answer: [
                  `   When a customer clicks a link or banner on your website, your unique
                                tracking code,it means that client can only be connected to your
                                account.`,
                  ` For more detailed information on how this works, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question:
                  'How can I check the number of users I have referred to winmasters?',
                answer: [
                  `    As an affiliate you have access to customer reporting, which you can
                                    find when you log in to your account. This will give you information
                                    on players but please be aware that their personal information will
                                    not be shared due to the Data Protection Act.`,
                ],
              },
              {
                question:
                  'Are there any countries where I cannot promote winmasters?',
                answer: [
                  `    Players from the following territories are not accepted at
                                        winmasters:`,
                  `United States of America and France.`,
                ],
              },
              {
                question: 'How can I check my sub-affiliates’ statistics?',
                answer: [
                  `   Our Reports allow you to view the number of sub-affiliates you have
                                            recruited and what earnings you have gained from them.`,
                  `Please contact your affiliate manager if you have any other
                                            questions relating to this.`,
                ],
              },
            ],
          },
          {
            title: 'Marketing tools',
            qanda: [
              {
                question:
                  'Where can I find marketing tools for winmasters products?',
                answer: [
                  `Once your winmasters Affiliates account has been approved, you will
                                be able to log in and find a range of text links, banners and more
                                in the ‘Media Gallery’ section of our interface.`,
                ],
              },
              {
                question:
                  'Can I request a special marketing tool for my website?',
                answer: [
                  `Special, exclusive tools can be arranged. Contact your affiliate
                                manager to discuss the possibilities.`,
                ],
              },
              {
                question:
                  'Can I offer visitors to my website an incentive to sign up?',
                answer: [
                  `winmasters website offer a range of incentives to new players. These
                                bonuses vary according to the product you are promoting and the
                                market which you are targeting. To check the latest promotions for
                                the product and market you are targeting, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question: 'Do you offer bonus codes?',
                answer: [
                  `Yes, we can offer bonus codes for winmasters. Please contact your
                                affiliate manager to request one.`,
                ],
              },
            ],
          },
          {
            title: 'Earnings and Payments',
            qanda: [
              {
                question: 'What are the available payment methods?',
                answer: [
                  `winmasters offers a number of payment methods including bank
                                transfer and Skrill (formerly Moneybookers). Please make sure that
                                your payment options are kept up-to-date under My Account & Payment
                                Information.`,
                ],
              },
              {
                question: 'When is my commission paid?',
                answer: [
                  ` We pay our affiliates 45 – 60 days upon payment request with a
                                minimum payment of 100 Euros. If you haven’t reached that level then
                                your earnings will be carried over to the following month.`,
                ],
              },
              {
                question: 'Where can I see my earnings?',
                answer: [
                  `You can view your statistics at any time via the Reports > Earnings
                                Reports section in the winmasters Affiliates interface.`,
                ],
              },
              {
                question: 'Is there a limit in my earnings?',
                answer: [
                  `There is no limit! We offer competitive and flexible reward plans to
                                reflect the quantity and quality of the players which you send to
                                us. For further details, please contact your affiliate manager.`,
                ],
              },
              {
                question:
                  'Where can I see my commission status and other information?',
                answer: [
                  `As soon as you log into your affiliate account, your payment
                                information will be displayed on the main page. More detailed
                                information can be obtained by clicking onto ‘Reports’, ‘Earnings
                                Report’ or ‘Payment Report’.`,
                ],
              },
              {
                question: `Are Sub-affiliates earnings paid on the same date as my regular
                                payments?`,
                answer: [
                  `Yes, your sub-affiliate commission is paid together with your
                                regular affiliate earnings every month. You can view your
                                sub-affiliates under Reports > Earnings Reports > Sub-Affiliates.`,
                ],
              },
            ],
          },
        ],
      },
    },
  },
  romania: {
    native: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Autentificare',
          loginLink: 'https://partners.winmasters.com/ro/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/ro/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/ro/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Home',
          aboutUs: 'Despre noi',
          whatWeOffer: 'Producse',
          testimonials: 'Testimonial',
          contact: 'Contactați-ne',
          rewardTiers: 'Comisioane',
          faq: 'FAQ',
          terms: 'Termene & Condiții',
        },
      },
      footer: {
        sponsors: 'Sponsor Oficial',
        socialMedia: 'Social Media',
        socialLink: {
          facebook: 'https://www.facebook.com//winmastersRomania',
          instagram: 'https://www.instagram.com/winmasters/',
          youtube: 'https://www.youtube.com/channel/UCzQRDWFh543SgqViBAE73SQ',
        },
        copyright: `© ${currentYear} winmasters este o marcă înregistrată. Toate drepturile rezervate.`,
      },
      homePage: {
        hero: {
          title: ['Transformă-ți audiența în'],
          typing: ['parteneri.', 'venit.', 'afacere.'],
          cta: 'Înregistrare',
        },
        steps: {
          title: 'Ești la 3 pași de primul tău comision',
          steps: [
            {
              iconUrl: '',
              title: 'Înregistrare',
              p: 'Tot ce trebuie să faci este să completezi detaliile tale în pagina de "Înregistrare" și să trimiți formularul. ',
              link: {
                text: 'Înregistrează-te acum!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Obține aprobarea',
              p: 'Solicitarea ta va fi evaluată de către echipa noastră de afiliere și, dacă întrunești condițiile, vei fi acceptat.',
            },
            {
              iconUrl: '',
              title: 'Obține venituri',
              p: 'După ce ai fost acceptat în programul de afiliere, poţi să începi să promovezi winmasters şi să obţii câştiguri imediate.',
            },
          ],
        },
        aboutUs: {
          title: 'Despre noi',
          paragraphs: [
            'Echipa de afiliere winmasters dispune de cunoștințe aprofundate de marketing afiliat și își propune să ofere un suport de înalt nivel profesional partenerilor săi.',
            'Echipa winmasters este formată din specialiști ale căror cunoștințe s-au fundamentat de-a lungul multor ani de experiență  în industria jocurilor de noroc online și de marketing afiliat. Acest lucru constituie un "pariu sigur" pentru fiecare partener care ar dori să coopereze cu winmasters.',
          ],
          subtitle: 'Principiile echipei noastre',
          list: [
            'Program de afiliere de top, generator de venituri',
            'Până la 50% cota de comision din venituri, care se aplică la toate produsele winmasters',
            'Abordare locală pentru clienți și afiliaţi, cu suport în 4 limbi diferite',
            'Cunoaștere profundă a fiecărei piețe și a necesităților sale',
            'Echipa noastră vă va oferi cel mai bun sprijin și consultanță, pentru a atinge obiectivele pe care vi le-ați propus să le atingeți prin afiliere și pentru a vă maximiza profitul.',
          ],
        },
        whatWeOffer: {
          title: 'Produse',
          tabTitles: ['Pariuri sportive', 'Cazino'],
          articles: [
            {
              for: 'Pariuri sportive',
              titles: ['Cote competitive', 'Pariuri Live'],
              paragraphs: [
                [
                  'Cotele winmasters sunt printre cele mai competitive de pe piață. Acoperim o gamă largă de piețe, de la cele mai mari evenimente globale la ligile locale și sporturi diferite din Europa și din întreaga lume. winmasters își propune să asigure tuturor clienților cea mai interesantă ofertă globală și locală.',
                ],
                [
                  'Pariurile live (Live betting) reprezintă una dintre opțiunile cele mai populare pentru clienții noștri și de aceea oferim peste 25.000 de evenimente pe lună, pe tot parcursul anului!',
                ],
              ],
            },
            {
              for: 'Cazino',
              titles: [
                'De ce Cazinoul winmasters?',
                'Bonus de bun venit și Promoții',
              ],
              paragraphs: [
                [
                  'Distracția este garantată. Cu cele trei Cazino lobby-uri diferite, plus Cazinoul nostru live cu dealeri live, oferim un portofoliu extins de jocuri, bonusuri generoase de bun venit și promoții în mod regulat. De asemenea, jackpot-urile sunt mari, unele dintre cele mai mari pe care le puteți găsi.',
                  'winmasters Cazino oferă cele mai bune jocuri dintre cele existente, de la furnizori de top, cum ar fi NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. Oferim o experiență de tip Cazino online la cel mai înalt nivel, cu cele mai noi jocuri de pe piață, urmărind cele mai recente tendințe. Trei destinații diferite de cazino sunt disponibile pentru crearea unei ideale "lumi de cazinou" virtual. Trăiește online experiența cazinoului real!',
                ],
                [
                  'Bonusul de bun venit al Cazinoului este conceput pentru a da satisfacție oricărui client, de orice naționalitate, de orice preferință și gust. Rotiri gratuite, bonusuri, turnee, toate acestea sunt adesea oferite jucătorilor fideli.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Best Bookmakers',
            paragraph:
              '"Dacă ești în căutarea unui parteneriat de succes, nu poți da greș cu programul de afiliere winmasters. Echipa este foarte prietenoasă și de încredere, mereu gata să ajute. Suntem convinși că vom avea un parteneriat puternic pe termen lung cu ei"',
            linkUrl: 'https://bestbookmakers.co.uk/',
            linkText: 'bestbookmakers.co.uk',
          },
          {
            title: 'bookmakers',
            paragraph:
              '"winmasters își pune amprenta pe industria de iGaming și e o adevărată plăcere să colaborezi cu o marcă atât de inovatoare. Oferă o foarte bună rată de conversie și sunt mereu disponibili pentru orice întrebare."',
            linkUrl: 'https://www.bookmakers.bet/',
            linkText: 'www.bookmakers.bet',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"winmasters este printre companiile de pariuri sportive cu cea mai rapidă creștere din Europa. Recomandăm fără rezerve colaborarea cu această echipă de profesioniști inovatori."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
          {
            title: 'Feeling Lucky',
            paragraph:
              '"winmasters is an established brand in the Romanian market, so we are pleased to promote them on Feeling Lucky. Moreover, we appreciate the initiative their affiliate managers take, from keeping us informed about new bonuses, promotions, etc. to discussing ways to boost our campaign."',
            linkUrl: 'https://feelinglucky.ro/',
            linkText: 'feelinglucky.ro',
          },
        ],
        contactUs: {
          title: 'Contactează-ne',
          button: 'Trimite',
          fields: {
            name: 'Nume',
            surname: 'Prenume',
            email: 'Email',
            message: 'Mesaj',
          },
        },
      },
      termsandconditions: {
        title: ['Termene și Condiții'],

        languageSelectors: {
          selectCountry: 'Selectează țara',
          selectLanguage: 'Selectează limba',
          countries: {
            Greece: {
              country: 'Grecia',
              languages: {
                english: 'Engleză',
                native: 'Greacă',
              },
            },
            Romania: {
              country: 'România',
              languages: {
                english: 'Engleză',
                native: 'Română',
              },
            },
            Cyprus: {
              country: 'Cipru',
              languages: {
                english: 'Engleză',
                native: 'Greacă',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'Engleză',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Planuri de recompensă'],
        descriptions: [
          'Venit net**',
          'Cotă venit net',
          'Comision Booster*',
          'Câștiguri totale',
        ],
        tabTitles: ['Pragul 1', 'Pragul 2', 'Pragul 3', 'Pragul 4', 'Pragul 5'],
        tierArticles: [
          {
            tier: 'Pragul 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Pragul 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Pragul 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Pragul 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Pragul 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Primești un comision Booster de 5% atunci când aduci mai mult de 40 de jucători noi, cu depuneri, într-o lună calendaristică!',
        earnings: {
          title: 'Cât de mult poți câștiga',
          info: [
            {
              description: 'Comision de sub-afiliat 5 %',
              amount: 'Nelimitat!',
            },
            {
              description: 'CPA/Recompense hibride €€€',
              amount: 'Nelimitat',
            },
          ],
        },
        extras: {
          title: 'Planul cotelor pe nivel de venituri',
          sections: [
            {
              paragraphs: [
                'În fiecare lună calendaristică, vei câștiga un procent din venitul net de la toți jucătorii pe care i-ai adus la winmasters.',
                'Procentajul cotei de venituri nete care îți va fi plătită în fiecare lună depinde de doi factori: veniturile nete și jucătorii noi care efectuează depuneri (a se vedea secțiunea comisionului Booster de mai jos).',
                'Dacă jucătorii pe care i-ai adus generează venituri nete, ești plătit un procent din acestea drept comision. Când jucătorii menționați generează un total de 10.000 € sau mai mult într-o lună calendaristică, procentul pe care-l vei primi va crește de la 25% la 30%. Când se generează mai mult de 50.000 €, cota ta din venitul net va crește la 45%',
                'În cazul în care balanța de venituri de la jucătorii tăi este negativă într-o lună, sumele vor fi reportate în luna următoare (acest lucru este adesea numit report negativ',
                '**Veniturile nete sunt definite după cum urmează: Venituri brute mai puțin costul vânzărilor (care cuprind impozite, taxe de rețea, comisioane de tranzacționare, costurile de bonus și prime).',
              ],
            },
            {
              title: 'Comisionul Booster',
              paragraphs: [
                'Atrage cel puţin 40 de noi jucători care deschid depozite într-o lună calendaristică și vei obține un bonus de 5% la partea ta de venituri nete.',
              ],
            },
            {
              title: 'Comision de sub-afiliat',
              paragraphs: [
                `Poți câștiga, de asemenea, un comision de sub-afiliat de 5%, prin recrutarea unui alt partener la programul de afiliere winmasters. Comisionul este calculat la recompensele sub-afiliaților și adăugat la comisionul tau lunar.`,
              ],
            },
            {
              title: 'CPA/Recompense hibride',
              paragraphs: [
                'Dacă ești interesat în a discuta un cost-pe-achiziție (CPA) sau de un plan de recompensă hibrid, te rugăm să contactezi managerul tau de afiliere.',
              ],
            },
            {
              title: 'Opțiuni de plată afiliați',
              paragraphs: [
                'Transfer bancar și Skrill (Moneybookers anterior). ',
              ],
            },
          ],
        },
        disclaimer2:
          'Te rugăm să reții că există posibilitatea ca, în unele cazuri, în funcție de țara în care te afli, unele dintre aceste opțiuni să nu fie disponibile.',
      },
      faq: {
        title: ['Întrebări frecvente'],
        sections: [
          {
            title: 'General',
            qanda: [
              {
                question: 'Cum funcționează programul de afiliere winmasters?',
                answer: [
                  `Programul de afiliere winmasters recompensează terții pentru direcţionarea clienților pe site-ul winmasters.`,
                  `Partenerii winmasters trimit trafic către site-urile noastre  prin intermediul unor link-uri ce conţin un cod de tracking,  iar atunci când utilizatorii se înregistrează în calitate de clienți , fac depuneri și joacă în platforma winmasters, îi recompensăm pe partenerii noştri cu un comision.`,
                ],
              },
              {
                question: 'Ce este un sub-afiliat?',
                answer: [
                  `    Un sub - afiliat este un nou partener (afiliat), pe care îl înscrieți la affiliates.winmasters.com. Puteți face acest lucru înscriindu-l prin intermediul link-urilor de afiliere pe  care le veți  găsi în contul dumneavoastră .
                        După înscriere, puteți obține câștiguri suplimentare pentru fiecare client pe care sub - afiliatul dvs. ni-l trimite. Puteți găsi link-ul dvs.  de sub - afiliat sub cuprinsul contului dvs.`,
                ],
              },
            ],
          },
          {
            title: 'Contul meu',
            qanda: [
              {
                question: 'Unde pot verifica statisticile contului meu?',
                answer: [
                  `       Când vă conectați la contul dvs. afiliat winmasters, veți avea posibilitatea  de a accesa o gamă largă de rapoarte care detaliază datele care ne-au fost transmise prin intermediul site-ului dvs., inclusiv toate tranzacțiile care au fost efectuate.`,
                  `  Dacă aveți întrebări cu privire la aceste rapoarte, atunci va rugam să folosiți formularul de contact sau să luați legătura direct cu managerul dvs. de afiliere.`,
                ],
              },
              {
                question: 'Cât de des sunt actualizate statisticile mele?',
                answer: [
                  `  Rapoartele în interfața winmasters afiliere sunt actualizate zilnic, de obicei la  3pm GMT/4pm BST. În cazul în care credeți că statisticile dvs. nu au fost actualizate sau aveți întrebări, vă rugăm să contactați managerul dvs. de afiliere.`,
                ],
              },
              {
                question: 'Cum sunt conectați utilizatorii la contul meu?',
                answer: [
                  `   Atunci când un client face click de pe un link sau banner  de pe site-ul dvs., codul dvs. unic de urmărire asigură că acel client poate fi asociat doar  contului dumneavoastră .
                        Pentru mai multe informații detaliate cu privire la modul în care funcționează acest lucru, vă rugăm să contactați managerul dvs. de  afiliere.`,
                ],
              },
              {
                question:
                  'Cum pot verifica numărul de utilizatori pe care i-am adus la winmasters?',
                answer: [
                  `    În calitate de afiliat, aveți acces la rapoartele clientului , pe care le puteți găsi atunci când vă conectați la contul dumneavoastră. Acest lucru vă va oferi informații cu privire la jucători , dar vă rugăm să fiți conștienți de faptul că informațiile lor personale nu vor fi partajate ca urmare a legislației privind protecția datelor.`,
                ],
              },
              {
                question: 'Există țări care nu pot promova winmasters?',
                answer: [
                  `    Jucătorii din următoarele țări nu sunt acceptați la winmasters:
                        Statele Unite ale Americii și Franța.`,
                ],
              },
              {
                question: 'Cum pot verifica statisticile sub- afiliaților mei?',
                answer: [
                  `   Rapoartele noastre vă permit să vizualizați numărul de sub- afiliaţi pe care i-ați recrutat și ce câștiguri ați obținut de la ei.`,
                  `Vă rugăm să contactați managerul dvs. de afiliere, dacă aveți orice alte întrebări referitoare la acest lucru.`,
                ],
              },
            ],
          },
          {
            title: 'Instrumente de marketing',
            qanda: [
              {
                question:
                  'Unde pot găsi instrumente de marketing pentru produsele winmasters?',
                answer: [
                  `Odată ce contul dvs. de afiliat winmasters a fost aprobat, veți putea să vă autentificați și să găsiți o serie de link-uri text, bannere precum şi alte instrumente de promovare în secțiunea " Media Gallery " din interfața  noastră.`,
                ],
              },
              {
                question:
                  'Pot solicita un instrument special de marketing pentru site-ul meu?',
                answer: [
                  `Pot fi făcute stabilite instrumente speciale, exclusive, pentru anumiţi afiliaţo. Pentru a discuta alternativele, contactaţi-l pe managerul Dvs de afiliere.`,
                ],
              },
              {
                question:
                  'Pot oferi vizitatorilor site-ului meu un stimulent să se înscrie?',
                answer: [
                  `Site-ul winmasters oferă jucătorilor noi o gamă largă de stimulente. Aceste bonusuri variază în funcție de produsul pe care îl promovați  și piața pe care o vizați. Pentru a verifica cele mai recente promoții pentru produsul  și piața  vizate, vă rugăm să contactați managerul dvs. de afiliere.`,
                ],
              },
              {
                question: 'Oferiți coduri de bonus?',
                answer: [
                  `Da, putem oferi coduri de bonus pentru winmasters . Vă rugăm să contactați managerul dvs. de afiliere pentru a solicita unul.`,
                ],
              },
            ],
          },
          {
            title: 'Câștiguri și Plăți',
            qanda: [
              {
                question: 'Care sunt metodele de plată disponibile?',
                answer: [
                  `winmasters oferă o serie de metode de plată, inclusiv transfer bancar și Skrill (Moneybookers anterior). Vă rugăm asigurați-vă că opțiunile dvs. de plată sunt ținute la zi în Contul meu > Informații de plată.`,
                ],
              },
              {
                question: 'Când este plătit comisionul meu?',
                answer: [
                  ` Plăţile către partnerii noştri se efectuează în termen de 15 - 20 zile de la cererea de plată, în cazul în care aţi atins limita minimă de 50 de euro. Dacă nu ați ajuns la acest nivel, atunci câștigurile dvs. vor fi reportate în luna următoare.`,
                ],
              },
              {
                question: 'Unde pot vedea câștigurile mele?',
                answer: [
                  `Puteți vizualiza statisticile dvs. în orice moment, prin intermediul Rapoarte > secțiunea Rapoarte  Venituri în interfața  winmasters afiliere.`,
                ],
              },
              {
                question: 'Există o limită a câștigurilor mele?',
                answer: [
                  `Nu există nici o limită! Oferim planuri de recompensare  competitive și flexibile, corespunzătoare numarului de jucători pe care ni-i trimiteţi şi calităţii acestora. Pentru mai multe detalii, vă rugăm să contactați managerul dvs. de afiliere.`,
                ],
              },
              {
                question:
                  'Unde pot vedea situația comisionului meu  și alte informații?',
                answer: [
                  `După ce vă conectaţi în contul dvs de afiliat, informațiile dvs. de plată va fi afișate pe pagina principală. Informațiile detaliate pot fi obținute prin click pe " Rapoarte" , "Raport Câștiguri " sau "Raport de plată ".`,
                ],
              },
              {
                question: `Câștigurile  sub–afiliaților sunt plătite la aceeași dată ca și plățile mele periodice?`,
                answer: [
                  `Da, comisionul sub – afiliatului dvs. este plătit împreună cu câștigurile de afiliere obişnuite, în fiecare lună. Puteți vedea  sub - afiliații în Rapoarte > Rapoarte Câștiguri > Sub - Afiliați.`,
                ],
              },
            ],
          },
        ],
      },
    },
    english: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Login',
          loginLink: 'https://partners.winmasters.com/en/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/en/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/en/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Home',
          aboutUs: 'About us',
          whatWeOffer: 'Products',
          testimonials: 'Testimonials',
          contact: 'Contact us',
          rewardTiers: 'Reward tiers',
          faq: 'FAQ',
          terms: 'Terms & Conditions',
        },
      },
      footer: {
        sponsors: 'Golden Sponsor',
        socialMedia: 'Social Media',
        socialLink: {
          facebook: 'https://www.facebook.com//winmastersRomania',
          instagram: 'https://www.instagram.com/winmasters/',
          youtube: 'https://www.youtube.com/channel/UCzQRDWFh543SgqViBAE73SQ',
        },
        copyright: `© ${currentYear} winmasters is a registered trademark. All rights reserved.`,
      },
      homePage: {
        hero: {
          title: ['Turn your audience into'],
          typing: ['partners.', 'income.', 'a business.'],
          cta: 'Register',
        },
        steps: {
          title: 'You are 3 steps away from earning your first revenue',
          steps: [
            {
              iconUrl: '',
              title: 'Register',
              p: 'All you have to do is fill in your details in the “Sign up” page and submit the registration form.',
              link: {
                text: ' Register now!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Get Approved',
              p: 'Your application will be reviewed by our Affiliate Team and if you meet the requirements you will be accepted.',
            },
            {
              iconUrl: '',
              title: 'Get Revenue',
              p: 'After your application is approved you will be able to start promoting winmasters and get instant revenue.',
            },
          ],
        },
        aboutUs: {
          title: 'About us',
          paragraphs: [
            'winmasters Affiliates team has a real understanding of affiliate marketing and aims to provide a top level professional support to its affiliates.',
            'winmasters team consists of people with many years of knowledge in the online gaming industry and affiliate marketing. This is a “sure bet” for every partner that would like to cooperate with winmasters.',
          ],
          subtitle: 'Our team’s principles',
          list: [
            'Industry-leading revenue generating affiliate program',
            'Up to 50% revenue share commission across all winmasters products',
            'Local approach for customers and affiliates, with support in 4 different languages',
            'Deep knowledge of each respective market and its needs',
            'Our team will provide you with the best support and knowledge in order to achieve your affiliate goals and maximize your profit.',
          ],
        },
        whatWeOffer: {
          title: 'What we offer',
          tabTitles: ['Sportsbook', 'Casino'],
          articles: [
            {
              for: 'sportsbook',
              titles: ['Competitive Odds', 'Live Betting'],
              paragraphs: [
                [
                  'winmasters prices are amongst the most competitive in the market. We also cover a wide range of markets, ranging from the biggest global events to local leagues and minor sports across Europe and worldwide. winmasters aims to provide the most interesting global and local offering to all customers.',
                ],
                [
                  'Live betting is one of the most popular options for our customers and that’s why we offer more than 25.000 events per month all over the year.',
                ],
              ],
            },
            {
              for: 'casino',
              titles: ['Why winmasters Casino?', 'Welcome Bonus & Promotions'],
              paragraphs: [
                [
                  'Fun is guaranteed. Casino and slots lobbies, plus our live Casino with live dealers we offer an extensive portfolio of games, generous welcome bonuses and promotions on a regular basis. Great jackpots amongst the higher you can find around.',
                  'winmasters Casino offers the best of breed games from the top providers such as NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. We are offering a top level online Casino experience with the newest games in the market and following its latest trends. Three different casino destinations are available creating the ideal virtual “casino world”. Live the real casino experience online!',
                ],
                [
                  'Casino welcome bonus is studied to be enjoyed from any customer of any nationality of any preference and taste. Free spins, deposit bonus, tournaments are often offered to the regulars.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Best Bookmakers',
            paragraph:
              '"If you’re looking for a lucrative partnership, you can’t go wrong with winmasters Affiliates. The team is very friendly and helpful, always ready to help us. We believe we will have strong and long-term partnership with them!"',
            linkUrl: 'https://bestbookmakers.co.uk/',
            linkText: 'bestbookmakers.co.uk',
          },
          {
            title: 'bookmakers',
            paragraph:
              '"winmasters are starting to leave their mark on the iGaming industry and it is truly a pleasure cooperating with such an innovating brand. They offer a high conversion rate and are readily available to answer any question you may have."',
            linkUrl: 'https://www.bookmakers.bet/',
            linkText: 'www.bookmakers.bet',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"Winmasters is one of the fastest growing betting companies in the European market. We unreservedly recommend working with such a professional and innovative brand."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
          {
            title: 'Feeling Lucky',
            paragraph:
              '"winmasters is an established brand in the Romanian market, so we are pleased to promote them on Feeling Lucky. Moreover, we appreciate the initiative their affiliate managers take, from keeping us informed about new bonuses, promotions, etc. to discussing ways to boost our campaign."',
            linkUrl: 'https://feelinglucky.ro/',
            linkText: 'feelinglucky.ro',
          },
        ],
        contactUs: {
          title: 'Contact us',
          button: 'Send',
          fields: {
            name: 'Name',
            surname: 'Surname',
            email: 'Email',
            message: 'Message',
          },
        },
      },
      termsandconditions: {
        title: ['Terms and Conditions'],

        languageSelectors: {
          selectCountry: 'Select the country',
          selectLanguage: 'Select the language',
          countries: {
            Greece: {
              country: 'Greece',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Romania: {
              country: 'Romania',
              languages: {
                english: 'English',
                native: 'Romanian',
              },
            },
            Cyprus: {
              country: 'Cyprus',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'English',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Reward Tiers'],
        descriptions: [
          'Net Revenue**',
          'Net Revenue Share',
          'Commission Booster*',
          'Earnings total',
        ],
        tabTitles: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
        tierArticles: [
          {
            tier: 'Tier 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Tier 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Tier 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Tier 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Tier 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Get a 5% Commission Booster when you send us 40+ First Time Active Players within a calendar month!',
        earnings: {
          title: 'How much you can earn',
          info: [
            {
              description: 'Sub-Affiliate Commission 5 %',
              amount: 'No limit!',
            },
            {
              description: 'CPA/Hybrid Rewards €€€',
              amount: 'No limit!',
            },
          ],
        },
        extras: {
          title: 'Tiered Revenue Share Plan',
          sections: [
            {
              paragraphs: [
                'Each calendar month, you will earn a percentage of the net revenue from all the players you have referred to winmasters.',
                'The percentage of net revenue share which you will be paid each month depends on two criteria: net revenue and new depositing players (see Commission Booster section below).',
                'As the players you refer generate net revenue, you are paid a percentage of this as a commission. When your referred players generate a total of €10,000 or more within a calendar month, the percentage which you receive will increase from 25% to 30%. When they generate more than €50,000, your net revenue share will increase to 45%.',
                'If the revenue from your players is negative in any month it will be carried over to the following month (this is often called negative carryover).',
                '**Net Revenue is defined as follows: Gross Revenue less Cost of Sales (comprising taxes, network fees, transaction fees, bonus costs and premiums).',
              ],
            },
            {
              title: 'Commission Booster',
              paragraphs: [
                'Refer 40 or more First Time Active players each month and get a 5% bonus on your net revenue share.',
              ],
            },
            {
              title: 'Sub-Affiliate Commission',
              paragraphs: [
                'You can also earn a sub-affiliate commission of 5% by recruiting another affiliate to the winmasters Affiliates program.',
                `The commission is calculated on your sub-affiliates' rewards and added to your monthly commission.`,
              ],
            },
            {
              title: 'CPA/Hybrid Deals',
              paragraphs: [
                'If you are interested in discussing a cost-per-acquisition (CPA) or hybrid reward plan, please contact your affiliate manager.',
              ],
            },
            {
              title: 'Affiliate Payment Options',
              paragraphs: [
                'Bank transfer and Skrill (formerly Moneybookers). ',
              ],
            },
          ],
        },
        disclaimer2:
          'Please note that in some cases depending on the country that you are based in, some of these options may not be available.',
      },
      faq: {
        title: [' Frequently Asked Questions'],
        sections: [
          {
            title: 'General',
            qanda: [
              {
                question: 'How does the winmasters Affiliate program work?',
                answer: [
                  `The winmasters Affiliate program rewards third parties for referring
                                customers to winmasters website.`,
                  `winmasters Affiliates send traffic to our sites via tracked links,
                                and when those users go on to register as customers, deposit and
                                play with us, we reward the affiliates with a commission.`,
                ],
              },
              {
                question: 'What is a sub-affiliate?',
                answer: [
                  `    A sub-affiliate is a new affiliate which you refer to
                                affiliates.winmasters.com. You can do this by getting them to sign
                                up via the new affiliate link which you will find in your account.`,
                  ` Once they have signed up, you can achieve additional earnings for
                                every customer that your sub-affiliate sends to us. You can find
                                your Sub-Affiliate Link under your account summary.`,
                ],
              },
            ],
          },
          {
            title: 'My Account',
            qanda: [
              {
                question: 'Where can I check my account statistics?',
                answer: [
                  `       When you log in to your winmasters Affiliates account, you will be
                                able to access a wide range of reports that detail the traffic that
                                has been sent to us via your site together with any transactions
                                that have been carried out.`,
                  `  If you have any questions regarding these reports then please use
                                our contact form or get in touch with your affiliate manager
                                directly.`,
                ],
              },
              {
                question: 'How often are my statistics updated?',
                answer: [
                  `  The reports in the winmasters Affiliates interface are updated
                            daily, usually by 3pm GMT/4pm BST. In the case that you believe that
                            your statistics have not been updated or you have any questions,
                            please contact your affiliate manager.`,
                ],
              },
              {
                question: 'How are the users linked to my account?',
                answer: [
                  `   When a customer clicks a link or banner on your website, your unique
                                tracking code,it means that client can only be connected to your
                                account.`,
                  ` For more detailed information on how this works, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question:
                  'How can I check the number of users I have referred to winmasters?',
                answer: [
                  `    As an affiliate you have access to customer reporting, which you can
                                    find when you log in to your account. This will give you information
                                    on players but please be aware that their personal information will
                                    not be shared due to the Data Protection Act.`,
                ],
              },
              {
                question:
                  'Are there any countries where I cannot promote winmasters?',
                answer: [
                  `    Players from the following territories are not accepted at
                                        winmasters:`,
                  `United States of America and France.`,
                ],
              },
              {
                question: 'How can I check my sub-affiliates’ statistics?',
                answer: [
                  `   Our Reports allow you to view the number of sub-affiliates you have
                                            recruited and what earnings you have gained from them.`,
                  `Please contact your affiliate manager if you have any other
                                            questions relating to this.`,
                ],
              },
            ],
          },
          {
            title: 'Marketing tools',
            qanda: [
              {
                question:
                  'Where can I find marketing tools for winmasters products?',
                answer: [
                  `Once your winmasters Affiliates account has been approved, you will
                                be able to log in and find a range of text links, banners and more
                                in the ‘Media Gallery’ section of our interface.`,
                ],
              },
              {
                question:
                  'Can I request a special marketing tool for my website?',
                answer: [
                  `Special, exclusive tools can be arranged. Contact your affiliate
                                manager to discuss the possibilities.`,
                ],
              },
              {
                question:
                  'Can I offer visitors to my website an incentive to sign up?',
                answer: [
                  `winmasters website offer a range of incentives to new players. These
                                bonuses vary according to the product you are promoting and the
                                market which you are targeting. To check the latest promotions for
                                the product and market you are targeting, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question: 'Do you offer bonus codes?',
                answer: [
                  `Yes, we can offer bonus codes for winmasters. Please contact your
                                affiliate manager to request one.`,
                ],
              },
            ],
          },
          {
            title: 'Earnings and Payments',
            qanda: [
              {
                question: 'What are the available payment methods?',
                answer: [
                  `winmasters offers a number of payment methods including bank
                                transfer and Skrill (formerly Moneybookers). Please make sure that
                                your payment options are kept up-to-date under My Account & Payment
                                Information.`,
                ],
              },
              {
                question: 'When is my commission paid?',
                answer: [
                  ` We pay our affiliates 45 – 60 days upon payment request with a
                                minimum payment of 100 Euros. If you haven’t reached that level then
                                your earnings will be carried over to the following month.`,
                ],
              },
              {
                question: 'Where can I see my earnings?',
                answer: [
                  `You can view your statistics at any time via the Reports > Earnings
                                Reports section in the winmasters Affiliates interface.`,
                ],
              },
              {
                question: 'Is there a limit in my earnings?',
                answer: [
                  `There is no limit! We offer competitive and flexible reward plans to
                                reflect the quantity and quality of the players which you send to
                                us. For further details, please contact your affiliate manager.`,
                ],
              },
              {
                question:
                  'Where can I see my commission status and other information?',
                answer: [
                  `As soon as you log into your affiliate account, your payment
                                information will be displayed on the main page. More detailed
                                information can be obtained by clicking onto ‘Reports’, ‘Earnings
                                Report’ or ‘Payment Report’.`,
                ],
              },
              {
                question: `Are Sub-affiliates earnings paid on the same date as my regular
                                payments?`,
                answer: [
                  `Yes, your sub-affiliate commission is paid together with your
                                regular affiliate earnings every month. You can view your
                                sub-affiliates under Reports > Earnings Reports > Sub-Affiliates.`,
                ],
              },
            ],
          },
        ],
      },
    },
  },
  cyprus: {
    native: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Είσοδος',
          loginLink: 'https://partners.winmasters.com/el/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/el/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/el/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Κεντρική',
          aboutUs: 'Σχετικά με εμάς',
          whatWeOffer: 'Προϊόντα',
          testimonials: 'Testimonials',
          contact: 'Επικοινωνία',
          rewardTiers: 'Προγράμματα Ανταμοιβής',
          faq: 'Συχνές ερωτήσεις',
          terms: 'Όροι & Προϋποθέσεις',
        },
      },
      footer: {
        sponsors: 'Μεγάλος Χορηγός',
        socialMedia: 'Social Media',
        socialLink: {
          facebook: 'https://www.facebook.com/WinmastersCy',
          instagram: 'https://www.instagram.com/winmasterscy/',
          youtube: 'https://www.youtube.com/channel/UCdiZHVzKgia9R_T80zeoupw',
        },
        copyright: `© ${currentYear} Η winmasters είναι ένα κατοχυρωμένο εμπορικό σήμα. Όλα τα δικαιώματα διατηρούνται.`,
      },
      homePage: {
        hero: {
          title: ['Μετατρέψτε το κοινό σας σε'],
          typing: ['συνεργάτες.', 'εισόδημα.', 'επιχείρηση.'],
          cta: 'Εγγραφή',
        },
        steps: {
          title:
            'Βρίσκεστε 3 βήματα μακριά από την απόκτηση των πρώτων εσόδων σας',
          steps: [
            {
              iconUrl: '',
              title: 'Εγγραφή',
              p: 'Το μόνο που έχετε να κάνετε είναι να συμπληρώσετε τα στοιχεία σας στη σελίδα "Εγγραφή" και να υποβάλετε τη φόρμα εγγραφής. ',
              link: {
                text: 'Εγγραφή τώρα!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Πάρτε έγκριση',
              p: 'Η αίτηση σας θα επαληθευθεί απο την ομάδα Affiliate της winmasters και εφόσoν τηρεί τα κριτήρια μας θα γίνει και αποδεκτή.',
            },
            {
              iconUrl: '',
              title: 'Αποκτήστε έσοδα',
              p: 'Εφόσoν η αίτηση σας έχει γίνει αποδεκτή θα μπορέσετε να ξεκινήσετε την προώθηση της winmasters και να έχετε άμεσα έσοδα.',
            },
          ],
        },
        aboutUs: {
          title: 'Σχετικά με εμάς',
          paragraphs: [
            'Η ομάδα Εξυπηρέτησης Συνεργατών της winmasters έχει μία πραγματική κατανόηση του μάρκετινγκ συνεργατών και στοχεύει στο να παρέχει ένα κορυφαίο επίπεδο υποστήριξης στους συνεργάτες της.',
            'Η ομάδα της  winmasters αποτελείται από άτομα με πολύχρονη γνώση στον κλάδο των διαδικτυακών τυχερών παιχνιδιών και το μάρκετινγκ συνεργατών. Αυτό είναι ένα «σίγουρο στοίχημα» για κάθε μέλος που θα ήθελε να συνεργαστεί με τη winmasters.',
          ],
          subtitle: 'Οι αρχές της ομάδας μας',
          list: [
            'Κορυφαίο πρόγραμμα affiliate για τη δημιουργία εσόδων.',
            'Το μερίδιο των εσόδων από προμήθεια σε όλα τα προϊόντα winmasters φτάνει έως και το 50%',
            'Για πελάτες και συνεργάτες η προσέγγιση σε τοπικό επίπεδο, υποστηρίζεται από 4 διαφορετικές γλώσσες',
            'Βαθιά γνώση της κάθε αντίστοιχης αγοράς και των αναγκών της',
            'Η ομάδα μας θα σας παρέχει την καλύτερη δυνατή υποστήριξη και τη γνώση ώστε να επιτύχετε τους στόχους συνεργατών και να μεγιστοποιήσετε το κέρδος σας.',
          ],
        },
        whatWeOffer: {
          title: 'Τι προσφέρουμε',
          tabTitles: ['Αθλητικό στοίχημα', 'Καζίνο'],
          articles: [
            {
              for: 'sportsbook',
              titles: [
                'Ανταγωντιστικές αποδόσεις',
                'Live Αθλητικό στοίχημα',
                'Στοίχημα μέσω κινητού',
                'Μπόνους καλωσορίσματος και Προσφορές',
              ],
              paragraphs: [
                [
                  'Οι αποδόσεις της winmasters είναι απο τις πιο ανταγωνιστικές της αγοράς. Καλύπτουμε μια ευρεία κλίμακα αγορών απο τις μεγαλύτερες παγκόσμιες διοργανώσεις μέχρι τα τοπικά πρωταθλήματα και μικρότερες κατηγορίες σε Ευρώπη και ολόκληρο τον κόσμο. Η winmasters στοχεύει να φέρει την πιο ενδιαφέρουσα διεθνή και τοπική προσφορά σε όλους τους πελάτες της.',
                ],
                [
                  `Το Live Αθλητικό στοίχημα είναι απο τα πιο δημοφιλή παιχνίδια των παικτών μας και γι'αυτο το λόγο προσφέρουμε περισσότερα απο 25.000 γεγονότα κάθε μήνα όλο τον χρόνο.`,
                ],
                [
                  `Η ιδέα του στοιχηματισμού καθ’ όδον γίνεται όλο και πιο δημοφιλής. Η σχεδίαση της ιστοσελίδας μας ανταποκρίνεται σε αυτήν την ιδέα και επιτρέπει στους χρήστες μας να χαρούν τις υπηρεσίες μας από οποιαδήποτε κινητή συσκευή.`,
                ],
                [
                  `Η winmasters προσφέρει μπόνους καλωσορίσματος προσαρμοσμένα σε κάθε αγορά, τα οποία κυμαίνονται από γενναιόδωρα μπόνους ανάλογα της κατάθεσης έως το ρίσκο των δωρεάν στοιχημάτων. Νοιαζόμαστε για τους πελάτες μας και τους προσεγγίζουμε με τον καλύτερο δυνατό τρόπο. Πολλές εκπλήξεις… `,
                ],
              ],
            },
            {
              for: 'casino',
              titles: [
                'Γιατί να προτιμήσω το Καζίνο στη winmasters;',
                'Μπόνους καλωσορίσματος και Προσφορές',
              ],
              paragraphs: [
                [
                  'Εγγυημένη διασκέδαση. Με λόμπυ Καζίνο και slots συν το live Καζίνο με αληθινούς ντίλερ προσφέρουμε μία ευρεία γκάμα παιχνιδιών, γενναιόδωρα μπόνους καλωσορίσματος και προσφορές σε τακτική βάση. Μεγάλα τζακ ποτ τα οποία είναι από τα υψηλότερα που μπορείτε να βρείτε στην αγορά.',
                  'H winmasters προσφέρει τα καλύτερα παιχνίδια Καζίνο που παράγονται από κορυφαίους προμηθευτές όπως οι NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. Προσφέρουμε μία κορυφαίου επιπέδου εμπειρία στο online Καζίνο με τα πιο καινούργια παιχνίδια στην αγορά και τα οποία ακολουθούν τις τελευταίες τάσεις. Τρεις διαφορετικοί προορισμοί καζίνο είναι διαθέσιμοι, δημιουργώντας τον ιδανικό εικονικό «κόσμο του καζίνο». Ζήστε online την πραγματική εμπειρία καζίνο!',
                ],
                [
                  'Το μπόνους καλωσορίσματος στο Καζίνο είναι μελετημένο για να το χαρεί κάθε πελάτης οποιασδήποτε εθνικότητας, με κάθε προτίμηση και γούστο. Δωρεάν περιστροφές (Free spins), μπόνους κατάθεσης, τουρνουά προσφέρονται συχνά στους τακτικούς πελάτες μας.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Betarades.gr',
            paragraph:
              '"Ως κορυφαίο ενημερωτικό σάιτ για το στοίχημα και το καζίνο δε θα μπορούσαμε να μη συνεργαζόμαστε με έναν εκ των κορυφαίων παρόχων. Η Winmasters αποτελεί μία αξιόπιστη και ασφαλής επιλογή για τους παίκτες του στοιχήματος και του casino και ως ειδικοί τη συστήνουμε ανεπιφύλακτα."',
            linkUrl: 'http://www.betarades.gr',
            linkText: 'www.betarades.gr',
          },
          {
            title: 'Casinoslot.gr',
            paragraph:
              '"Ένας από τους λίγους premium συνεργάτες μας εδώ και χρόνια έχει καταφέρει να κερδίσει την εμπιστοσύνη του κοινού μας, που είναι απαιτητικό και έμπειρο. Το καζίνο της Winmasters αποτελεί μία κορυφαία επιλογή συνδυάζοντας τη μεγάλη γκάμα παιχνιδιών και το εξαιρετικό περιβάλλον πλοήγησης με την αξιοπιστία και την ασφάλεια συναλλαγών μαζί με τις άμεσες πληρωμές. Αδιαμφισβήτητη επιλογή."',
            linkUrl: 'https://www.casinoslot.gr/',
            linkText: 'www.casinoslot.gr',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"Η winmasters αποτελεί μια από τις ταχύτερα ανερχόμενες στοιχηματικές εταιρίες στην Ευρωπαϊκή αγορά. Προτείνουμε ανεπιφύλακτα την συνεργασία με ένα τόσο επαγγελματικό και καινοτόμο brand."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
        ],
        contactUs: {
          title: 'Επικοινωνία',
          button: 'Αποστολή',
          fields: {
            name: 'Όνομα',
            surname: 'Επώνυμο',
            email: 'Email',
            message: 'Μήνυμα',
          },
        },
      },
      termsandconditions: {
        title: ['Όροι & Προϋποθέσεις'],

        languageSelectors: {
          selectCountry: 'Επιλέξτε τη χώρα',
          selectLanguage: 'Επιλέξτε τη γλώσσα',
          countries: {
            Greece: {
              country: 'Ελλάδα',
              languages: {
                english: 'Αγγλικά',
                native: 'Ελληνικά',
              },
            },
            Romania: {
              country: 'Ρουμανία',
              languages: {
                english: 'Αγγλικά',
                native: 'Ρουμάνικα',
              },
            },
            Cyprus: {
              country: 'Κύπρος',
              languages: {
                english: 'Αγγλικά',
                native: 'Ελληνικά',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'Αγγλικά',
                spanish: 'Español',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Προγράμματα Ανταμοιβής'],
        descriptions: [
          'Καθαρά Έσοδα**',
          'Καθαρό Μερίδιο Εσόδων',
          'Ενισχυτική Προμήθεια*',
          'Συνολικά Κέρδη',
        ],
        tabTitles: ['Επίπ. 1', 'Επίπ. 2', 'Επίπ. 3', 'Επίπ. 4', 'Επίπ. 5'],
        tierArticles: [
          {
            tier: 'Επίπεδο 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Επίπεδο 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Επίπεδο 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Επίπεδο 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Επίπεδο 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Λάβετε μία Ενισχυτική Προμήθεια +5% όταν θα μας στείλετε 40+ Παίκτες που θα πραγματοποιήσουν Νέα Κατάθεση εντός ενός ημερολογιακού μήνα!',
        earnings: {
          title: 'Πόσα μπορείτε να κερδίσετε',
          info: [
            {
              description: 'Προμήθεια Υπό-Συνεργάτη 5 %',
              amount: 'Χωρίς όριο!',
            },
            {
              description: 'CPA/Υβριδικές Ανταμοιβές €€€',
              amount: 'Χωρίς όριο!',
            },
          ],
        },
        extras: {
          title: 'Πρόγραμμα Κλιμακωτής Κατανομής Μεριδίων',
          sections: [
            {
              paragraphs: [
                'Κάθε ημερολογιακό μήνα, θα κερδίζετε ένα ποσοστό των καθαρών εσόδων από όλους τους παίκτες που έχετε παραπέμψει στη winmasters.',
                'Το ποσοστό του μεριδίου των καθαρών εσόδων που θα πρέπει να καταβληθεί κάθε μήνα εξαρτάται από δύο κριτήρια: τα καθαρά έσοδα και τους νέους παίκτες που θα πραγματοποιήσουν κατάθεση (δείτε την παράγραφο Ενισχυτική Προμήθεια παρακάτω).',
                'Καθώς οι παίκτες που παραπέμψατε θα παράγουν καθαρά έσοδα, θα σας δοθεί ένα ποσοστό από αυτό ως προμήθεια. Όταν οι παίκτες που παραπέμψατε παράγουν συνολικά € 10.000 ή περισσότερο εντός ενός ημερολογιακού μήνα, το ποσοστό που θα λάβετε θα αυξηθεί από 25% έως 30%. Όταν θα παράγουν πάνω από € 50,000, το καθαρό μερίδιο των εσόδων σας θα αυξηθεί στο 45%.',
                'Εάν τα έσοδα από τους παίκτες σας είναι αρνητικά σε οποιοδήποτε μήνα, θα μεταφέρονται στον επόμενο μήνα (αυτό συνήθως ονομάζεται «αρνητική μεταφορά»).',
                '** Τα Καθαρά Έσοδα ορίζονται ως εξής: Ακαθάριστα Έσοδα μείον Κόστος πωλήσεων (συμπεριλαμβανομένων των φόρων, τελών του δικτύου, τα έξοδα συναλλαγών, τα κόστη των μπόνους και τις πριμοδοτήσεις).',
              ],
            },
            {
              title: 'Ενισχυτική Προμήθεια',
              paragraphs: [
                'Κάθε μήνα συστήστε 40 ή περισσότερους νέους παίκτες που θα πραγματοποιήσουν κατάθεση και κερδίστε ένα μπόνους 5% επί του μεριδίου των καθαρών εσόδων σας.',
              ],
            },
            {
              title: 'Προμήθεια Υπό-Συνεργάτη ',
              paragraphs: [
                'Μπορείτε επίσης να κερδίσετε μια προμήθεια υπο-συνεργάτη του 5% με το να φέρετε άλλον ένα συνεργάτη στο  πρόγραμμα Συνεργατών της winmasters.',
                `Η προμήθεια υπολογίζεται επί των ανταμοιβών των υπο-συνεργατών σας και προστίθεται στη μηνιαία προμήθεια σας.`,
              ],
            },
            {
              title: 'CPA/Υβριδικές Συμφωνίες',
              paragraphs: [
                'Εάν σας ενδιαφέρει να συζητήσουμε ένα κόστος-ανά-απόκτηση πελάτη (CPA) ή ένα υβριδικό πρόγραμμα ανταμοιβής, παρακαλούμε επικοινωνήστε με τον υπεύθυνο συνεργατών του λογαριασμού σας.',
              ],
            },
            {
              title: 'Επιλογές Πληρωμής Συνεργάτη',
              paragraphs: [
                'Τραπεζικό έμβασμα και Skrill (πρώην Moneybookers).',
              ],
            },
          ],
        },
        disclaimer2:
          'Παρακαλώ σημειώστε ότι, σε ορισμένες περιπτώσεις, ανάλογα με τη χώρα όπου βρίσκεστε, μερικές από αυτές τις επιλογές πιθανόν να μην είναι διαθέσιμες.',
      },
      faq: {
        title: [' Συχνές ερωτήσεις'],
        sections: [
          {
            title: 'Γενικές',
            qanda: [
              {
                question: 'Τι είναι το Πρόγραμμα Συνεργατών της winmasters;',
                answer: [
                  `Το Πρόγραμμα Συνεργατών της winmasters ανταμείβει τρίτα συμβαλλόμενα μέρη για την παραπομπή πελατών στην ιστοσελίδα της winmasters.`,
                  `Οι Συνεργάτες της winmasters στέλνουν κίνηση στις ιστοσελίδες μας μέσω παρακολουθούμενων συνδέσμων, και όταν αυτοί οι χρήστες προχωρούν στην εγγραφή τους ως πελάτες, καταθέσουν και παίξουν σε εμάς, εμείς ανταμείβουμε τους συνεργάτες με μία προμήθεια.`,
                ],
              },
              {
                question: 'Τι είναι ο Υπο-Συνεργάτης;',
                answer: [
                  `Ο υπο-συνεργάτης είναι ένας νέος συνεργάτης τον οποίο εσείς παραπέμψατε στην affiliates.winmasters.com. Μπορείτε να το πράξετε αυτό με το να τους βάλετε να εγγραφούν μέσω του συνδέσμου νέου συνεργάτη το οποίο θα βρείτε στο λογαριασμό σας.`,
                  `Από τη στιγμή που θα εγγραφούν, μπορείτε να επιτύχετε επιπλέον κέρδη για κάθε πελάτη που ο υπο-συνεργάτης θα στέλνει σε εμάς. Μπορείτε να βρείτε το Σύνδεσμο Υπο-Συνεργάτη στη σύνοψη του λογαριασμού σας.`,
                ],
              },
            ],
          },
          {
            title: 'Ο Λογαριασμός μου',
            qanda: [
              {
                question:
                  'Πού μπορώ να ελέγξω τα στατιστικά του λογαριασμού μου;',
                answer: [
                  `Όταν συνδεθείτε στο λογαριασμό Συνεργατών της winmasters θα είστε σε θέση να έχετε πρόσβαση σε μία ευρεία γκάμα αναφορών που περιγράφουν λεπτομερώς την κίνηση που μας έχει αποσταλεί μέσω της ιστοσελίδας σας μαζί με οποιεσδήποτε συναλλαγές έχουν πραγματοποιηθεί.`,
                  `Εάν έχετε οποιεσδήποτε ερωτήσεις σχετικά με αυτές τις αναφορές, τότε παρακαλούμε να χρησιμοποιήσετε τη φόρμα επικοινωνίας μας ή να έρθετε άμεσα σε επαφή με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question: 'Πόσο συχνά ενημερώνονται τα στατιστικά μου;',
                answer: [
                  `Οι αναφορές στη διασύνδεση Συνεργατών της winmasters ενημερώνονται καθημερινά, συνήθως μέχρι τις 3μμ GMT / 4μμ BST. Σε περίπτωση που πιστεύετε ότι τα στατιστικά σας δεν έχουν ενημερωθεί ή έχετε οποιεσδήποτε απορίες, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question: 'Πώς οι χρήστες συνδέονται με το λογαριασμό μου;',
                answer: [
                  `Όταν ένας πελάτης κάνει κλικ μέσα από ένα σύνδεσμο ή banner στην ιστοσελίδα σας, ο μοναδικός κώδικας παρακολούθησης σας, σημαίνει ότι ο πελάτης μπορεί να συνδεθεί μόνο με το λογαριασμό σας.`,
                  `Για πιο λεπτομερείς πληροφορίες σχετικά με το πώς λειτουργεί αυτή η διαδικασία, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question:
                  'Πώς μπορώ να ελέγξω τον αριθμό των χρηστών που έχω παραπέμψει στη winmasters;',
                answer: [
                  `Ως συνεργάτης έχετε πρόσβαση στην αναφορά του πελάτη, την οποία μπορείτε να βρείτε όταν συνδεθείτε στο λογαριασμό σας. Αυτή θα σας δώσει πληροφορίες σχετικά με τους παίκτες, αλλά πρέπει να γνωρίζετε ότι τα προσωπικά στοιχεία τους δεν θα κοινοποιηθούν, λόγω του νόμου περί Προστασίας Προσωπικών Δεδομένων.`,
                ],
              },
              {
                question:
                  'Υπάρχουν χώρες στις οποίες εκεί δεν μπορώ να προωθήσω τη winmasters;',
                answer: [
                  `Παίκτες από τις ακόλουθες επικράτειες δεν γίνονται αποδεκτοί στη winmasters:`,
                  `Ηνωμένες Πολιτείες της Αμερικής και Γαλλία.`,
                ],
              },
              {
                question:
                  'Πως μπορώ να ελέγξω τα στατιστικά των υπο-συνεργατών μου;',
                answer: [
                  `Οι Αναφορές μας σας επιτρέπουν να δείτε τον αριθμό των υπο-συνεργατών που έχετε προσελκύσει και τι κέρδη έχετε αποκομίσει από αυτούς.`,
                  `Παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας, αν έχετε οποιεσδήποτε άλλες ερωτήσεις σχετικά με αυτό.`,
                ],
              },
            ],
          },
          {
            title: 'Εργαλεία Μάρκετινγκ',
            qanda: [
              {
                question:
                  'Πού μπορώ να βρω εργαλεία μάρκετινγκ για τα προϊόντα της winmasters;',
                answer: [
                  `Από τη στιγμή που ο λογαριασμός σας Συνεργάτη στη winmasters έχει εγκριθεί, θα είστε σε θέση να συνδεθείτε και να βρείτε μια σειρά από συνδέσμους κειμένων, banners και περισσότερα στην ενότητα "Πολυμέσα"/(Media Gallery) της διασύνδεσης μας.`,
                ],
              },
              {
                question:
                  'Μπορώ να ζητήσω ένα ειδικό εργαλείο μάρκετινγκ για την ιστοσελίδα μου;',
                answer: [
                  `Ειδικά, αποκλειστικά εργαλεία μπορούν να κανονιστούν. Επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας για να συζητήσετε τις δυνατότητες.`,
                ],
              },
              {
                question:
                  'Μπορώ να προσφέρω στους επισκέπτες της ιστοσελίδας μου, ένα κίνητρο για να εγγραφούν;',
                answer: [
                  `Η ιστοσελίδα της winmasters προσφέρει μια σειρά από κίνητρα για νέους παίκτες. Αυτά τα μπόνους ποικίλουν ανάλογα με το προϊόν που προωθείτε και την αγορά στην οποία στοχεύετε. Για να ελέγξετε τις τελευταίες προωθητικές ενέργειες για το προϊόν και την αγορά όπου στοχεύετε, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question: 'Προσφέρετε κωδικούς μπόνους;',
                answer: [
                  `Ναι, μπορούμε να προσφέρουμε κωδικούς μπόνους για τη winmasters. Παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας για να αιτηθείτε μία προωθητική ενέργεια.`,
                ],
              },
            ],
          },
          {
            title: 'Κέρδη και Πληρωμές',
            qanda: [
              {
                question: 'Ποιες είναι οι διαθέσιμες μέθοδοι πληρωμής;',
                answer: [
                  `Η winmasters προσφέρει μια σειρά από μεθόδους πληρωμής συμπεριλαμβανομένων των τραπεζικών εμβασμάτων και της Skrill (πρώην Moneybookers). Παρακαλούμε βεβαιωθείτε ότι οι επιλογές πληρωμής σας είναι ενημερωμένες στο «Ο λογαριασμός μου> Πληροφορίες Πληρωμής».`,
                ],
              },
              {
                question: 'Πότε καταβάλλεται η προμήθειά μου;',
                answer: [
                  `Πληρώνουμε τους Συνεργάτες μας 45 - 60 ημερών από την αίτηση πληρωμής, με ελάχιστη πληρωμή το ποσό των 100 ευρώ. Αν δεν έχετε φτάσει σε αυτό το επίπεδο, τότε τα κέρδη σας θα μεταφερθούν στον επόμενο μήνα.`,
                ],
              },
              {
                question: 'Που μπορώ να δω τα κέρδη μου;',
                answer: [
                  `Μπορείτε να δείτε τα στατιστικά σας ανά πάσα στιγμή μέσω της ενότητας «Αναφορές > Αναφορές Κερδών» στη διασύνδεση Συνεργατών της winmasters.`,
                ],
              },
              {
                question: 'Υπάρχει κάποιο όριο στα κέρδη μου;',
                answer: [
                  `Δεν υπάρχει όριο! Προσφέρουμε ανταγωνιστική και ευέλικτη ανταμοιβή η οποία έχει σχεδιασθεί για να αντικατοπτρίζει την ποσότητα και την ποιότητα των παικτών που μας στέλνετε. Για περισσότερες πληροφορίες, παρακαλούμε επικοινωνήστε με τον υπεύθυνο του λογαριασμού σας.`,
                ],
              },
              {
                question:
                  'Που μπορώ να δω την κατάσταση της προμήθειάς μου και άλλες πληροφορίες;',
                answer: [
                  `Μόλις συνδεθείτε στο λογαριασμό σας Συνεργάτη, τα στοιχεία της πληρωμής σας θα εμφανισθούν στην κύρια σελίδα. Μπορείτε να λάβετε πιο λεπτομερείς πληροφορίες κάνοντας κλικ στο «Αναφορές», «Αναφορές Κερδών» ή «Αναφορά Πληρωμών».`,
                ],
              },
              {
                question: `Τα κέρδη των υπο-συνεργατών καταβάλλονται την ίδια ημερομηνία με τις τακτικές πληρωμές μου;`,
                answer: [
                  `Ναι, οι προμήθειες από τους υπο-συνεργάτες σας καταβάλλονται κάθε μήνα από κοινού με τα τακτικά κέρδη συνεργάτη. Μπορείτε να δείτε τους υπο-συνεργάτες σας στην ενότητα «Αναφορές > Κέρδη Αναφορών > Υπό-Συνεργάτες».`,
                ],
              },
            ],
          },
        ],
      },
    },
    english: {
      navigation: {
        countriesShort: {
          com: 'COM',
          greece: 'GR',
          romania: 'RO',
          cyprus: 'CY',
        },
        language: {
          com: {
            country: 'Com',
            english: 'English',
            spanish: 'Español',
          },
          greece: {
            country: 'Ελλάδα',
            english: 'English',
            native: 'Ελληνικά',
          },
          romania: {
            country: 'Ρουμανία',
            english: 'English',
            native: 'Română',
          },
          cyprus: {
            country: 'Κύπρος',
            english: 'English',
            native: 'Ελληνικά',
          },
        },
        login: {
          login: 'Login',
          loginLink: 'https://partners.winmasters.com/en/index/popup/#login',
        },
        registration: {
          registrationIframe:
            'https://partners.winmasters.com/en/index/popup/#signup',
          registrationLink:
            'https://partners.winmasters.com/en/index/popup/#signup',
        },
        navigationLinks: {
          home: 'Home',
          aboutUs: 'About us',
          whatWeOffer: 'Products',
          testimonials: 'Testimonials',
          contact: 'Contact us',
          rewardTiers: 'Reward tiers',
          faq: 'FAQ',
          terms: 'Terms & Conditions',
        },
      },
      footer: {
        sponsors: 'Golden Sponsor',
        socialMedia: 'Social Media',
        socialLink: {
          facebook: 'https://www.facebook.com/WinmastersCy',
          instagram: 'https://www.instagram.com/winmasterscy/',
          youtube: 'https://www.youtube.com/channel/UCdiZHVzKgia9R_T80zeoupw',
        },
        copyright: `© ${currentYear} winmasters is a registered trademark. All rights reserved.`,
      },
      homePage: {
        hero: {
          title: ['Turn your audience into'],
          typing: ['partners.', 'income.', 'a business.'],
          cta: 'Register',
        },
        steps: {
          title: 'You are 3 steps away from earning your first revenue',
          steps: [
            {
              iconUrl: '',
              title: 'Register',
              p: 'All you have to do is fill in your details in the “Sign up” page and submit the registration form.',
              link: {
                text: ' Register now!',
                href: '/register',
              },
            },
            {
              iconUrl: '',
              title: 'Get Approved',
              p: 'Your application will be reviewed by our Affiliate Team and if you meet the requirements you will be accepted.',
            },
            {
              iconUrl: '',
              title: 'Get Revenue',
              p: 'After your application is approved you will be able to start promoting winmasters and get instant revenue.',
            },
          ],
        },
        aboutUs: {
          title: 'About us',
          paragraphs: [
            'winmasters Affiliates team has a real understanding of affiliate marketing and aims to provide a top level professional support to its affiliates.',
            'winmasters team consists of people with many years of knowledge in the online gaming industry and affiliate marketing. This is a “sure bet” for every partner that would like to cooperate with winmasters.',
          ],
          subtitle: 'Our team’s principles',
          list: [
            'Industry-leading revenue generating affiliate program',
            'Up to 50% revenue share commission across all winmasters products',
            'Local approach for customers and affiliates, with support in 4 different languages',
            'Deep knowledge of each respective market and its needs',
            'Our team will provide you with the best support and knowledge in order to achieve your affiliate goals and maximize your profit.',
          ],
        },
        whatWeOffer: {
          title: 'What we offer',
          tabTitles: ['Sportsbook', 'Casino'],
          articles: [
            {
              for: 'sportsbook',
              titles: ['Competitive Odds', 'Live Betting'],
              paragraphs: [
                [
                  'winmasters prices are amongst the most competitive in the market. We also cover a wide range of markets, ranging from the biggest global events to local leagues and minor sports across Europe and worldwide. winmasters aims to provide the most interesting global and local offering to all customers.',
                ],
                [
                  'Live betting is one of the most popular options for our customers and that’s why we offer more than 25.000 events per month all over the year.',
                ],
              ],
            },
            {
              for: 'casino',
              titles: ['Why winmasters Casino?', 'Welcome Bonus & Promotions'],
              paragraphs: [
                [
                  'Fun is guaranteed. Casino and slots lobbies, plus our live Casino with live dealers we offer an extensive portfolio of games, generous welcome bonuses and promotions on a regular basis. Great jackpots amongst the higher you can find around.',
                  'winmasters Casino offers the best of breed games from the top providers such as NetEnt, Betsoft, PlayNgo, iSoftbet, Evolution Live Casino, Quickspin, Pragmatic Play. We are offering a top level online Casino experience with the newest games in the market and following its latest trends. Three different casino destinations are available creating the ideal virtual “casino world”. Live the real casino experience online!',
                ],
                [
                  'Casino welcome bonus is studied to be enjoyed from any customer of any nationality of any preference and taste. Free spins, deposit bonus, tournaments are often offered to the regulars.',
                ],
              ],
            },
          ],
        },
        testimonials: [
          {
            title: 'Best Bookmakers',
            paragraph:
              '"If you’re looking for a lucrative partnership, you can’t go wrong with winmasters Affiliates. The team is very friendly and helpful, always ready to help us. We believe we will have strong and long-term partnership with them!"',
            linkUrl: 'https://bestbookmakers.co.uk/',
            linkText: 'bestbookmakers.co.uk',
          },
          {
            title: 'bookmakers',
            paragraph:
              '"winmasters are starting to leave their mark on the iGaming industry and it is truly a pleasure cooperating with such an innovating brand. They offer a high conversion rate and are readily available to answer any question you may have."',
            linkUrl: 'https://www.bookmakers.bet/',
            linkText: 'www.bookmakers.bet',
          },
          {
            title: 'Topbookmakers',
            paragraph:
              '"Winmasters is one of the fastest growing betting companies in the European market. We unreservedly recommend working with such a professional and innovative brand."',
            linkUrl: 'https://www.topbookmakers.com.cy/',
            linkText: 'www.topbookmakers.com.cy',
          },
        ],
        contactUs: {
          title: 'Contact us',
          button: 'Send',
          fields: {
            name: 'Name',
            surname: 'Surname',
            email: 'Email',
            message: 'Message',
          },
        },
      },
      termsandconditions: {
        title: ['Terms and Conditions'],

        languageSelectors: {
          selectCountry: 'Select the country',
          selectLanguage: 'Select the language',
          countries: {
            Greece: {
              country: 'Greece',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Romania: {
              country: 'Romania',
              languages: {
                english: 'English',
                native: 'Romanian',
              },
            },
            Cyprus: {
              country: 'Cyprus',
              languages: {
                english: 'English',
                native: 'Greek',
              },
            },
            Com: {
              country: 'Com',
              languages: {
                english: 'English',
                spanish: 'Spanish',
              },
            },
          },
        },
      },
      rewardTiers: {
        title: ['Reward Tiers'],
        descriptions: [
          'Net Revenue**',
          'Net Revenue Share',
          'Commission Booster*',
          'Earnings total',
        ],
        tabTitles: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
        tierArticles: [
          {
            tier: 'Tier 1',
            amounts: ['€ 0 - 10,000', '25 %', '+5 %', '30 %'],
          },
          {
            tier: 'Tier 2',
            amounts: ['€ 10,001 - 20,000', '30 %', '+5 %', '35 %'],
          },
          {
            tier: 'Tier 3',
            amounts: ['€ 20,001 - 30,000', '35 %', '+5 %', '40 %'],
          },
          {
            tier: 'Tier 4',
            amounts: ['€ 30,001 - 50,000', '40 %', '+5 %', '45 %'],
          },
          {
            tier: 'Tier 5',
            amounts: ['€ 50,001 +', '45 %', '+5 %', '50 %'],
          },
        ],
        disclaimer:
          '* Get a 5% Commission Booster when you send us 40+ First Time Active Players within a calendar month!',
        earnings: {
          title: 'How much you can earn',
          info: [
            {
              description: 'Sub-Affiliate Commission 5 %',
              amount: 'No limit!',
            },
            {
              description: 'CPA/Hybrid Rewards €€€',
              amount: 'No limit!',
            },
          ],
        },
        extras: {
          title: 'Tiered Revenue Share Plan',
          sections: [
            {
              paragraphs: [
                'Each calendar month, you will earn a percentage of the net revenue from all the players you have referred to winmasters.',
                'The percentage of net revenue share which you will be paid each month depends on two criteria: net revenue and new depositing players (see Commission Booster section below).',
                'As the players you refer generate net revenue, you are paid a percentage of this as a commission. When your referred players generate a total of €10,000 or more within a calendar month, the percentage which you receive will increase from 25% to 30%. When they generate more than €50,000, your net revenue share will increase to 45%.',
                'If the revenue from your players is negative in any month it will be carried over to the following month (this is often called negative carryover).',
                '**Net Revenue is defined as follows: Gross Revenue less Cost of Sales (comprising taxes, network fees, transaction fees, bonus costs and premiums).',
              ],
            },
            {
              title: 'Commission Booster',
              paragraphs: [
                'Refer 40 or more First Time Active players each month and get a 5% bonus on your net revenue share.',
              ],
            },
            {
              title: 'Sub-Affiliate Commission',
              paragraphs: [
                'You can also earn a sub-affiliate commission of 5% by recruiting another affiliate to the winmasters Affiliates program.',
                `The commission is calculated on your sub-affiliates' rewards and added to your monthly commission.`,
              ],
            },
            {
              title: 'CPA/Hybrid Deals',
              paragraphs: [
                'If you are interested in discussing a cost-per-acquisition (CPA) or hybrid reward plan, please contact your affiliate manager.',
              ],
            },
            {
              title: 'Affiliate Payment Options',
              paragraphs: [
                'Bank transfer and Skrill (formerly Moneybookers). ',
              ],
            },
          ],
        },
        disclaimer2:
          'Please note that in some cases depending on the country that you are based in, some of these options may not be available.',
      },
      faq: {
        title: [' Frequently Asked Questions'],
        sections: [
          {
            title: 'General',
            qanda: [
              {
                question: 'How does the winmasters Affiliate program work?',
                answer: [
                  `The winmasters Affiliate program rewards third parties for referring
                                customers to winmasters website.`,
                  `winmasters Affiliates send traffic to our sites via tracked links,
                                and when those users go on to register as customers, deposit and
                                play with us, we reward the affiliates with a commission.`,
                ],
              },
              {
                question: 'What is a sub-affiliate?',
                answer: [
                  `    A sub-affiliate is a new affiliate which you refer to
                                affiliates.winmasters.com. You can do this by getting them to sign
                                up via the new affiliate link which you will find in your account.`,
                  ` Once they have signed up, you can achieve additional earnings for
                                every customer that your sub-affiliate sends to us. You can find
                                your Sub-Affiliate Link under your account summary.`,
                ],
              },
            ],
          },
          {
            title: 'My Account',
            qanda: [
              {
                question: 'Where can I check my account statistics?',
                answer: [
                  `       When you log in to your winmasters Affiliates account, you will be
                                able to access a wide range of reports that detail the traffic that
                                has been sent to us via your site together with any transactions
                                that have been carried out.`,
                  `  If you have any questions regarding these reports then please use
                                our contact form or get in touch with your affiliate manager
                                directly.`,
                ],
              },
              {
                question: 'How often are my statistics updated?',
                answer: [
                  `  The reports in the winmasters Affiliates interface are updated
                            daily, usually by 3pm GMT/4pm BST. In the case that you believe that
                            your statistics have not been updated or you have any questions,
                            please contact your affiliate manager.`,
                ],
              },
              {
                question: 'How are the users linked to my account?',
                answer: [
                  `   When a customer clicks a link or banner on your website, your unique
                                tracking code,it means that client can only be connected to your
                                account.`,
                  ` For more detailed information on how this works, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question:
                  'How can I check the number of users I have referred to winmasters?',
                answer: [
                  `    As an affiliate you have access to customer reporting, which you can
                                    find when you log in to your account. This will give you information
                                    on players but please be aware that their personal information will
                                    not be shared due to the Data Protection Act.`,
                ],
              },
              {
                question:
                  'Are there any countries where I cannot promote winmasters?',
                answer: [
                  `    Players from the following territories are not accepted at
                                        winmasters:`,
                  `United States of America and France.`,
                ],
              },
              {
                question: 'How can I check my sub-affiliates’ statistics?',
                answer: [
                  `   Our Reports allow you to view the number of sub-affiliates you have
                                            recruited and what earnings you have gained from them.`,
                  `Please contact your affiliate manager if you have any other
                                            questions relating to this.`,
                ],
              },
            ],
          },
          {
            title: 'Marketing tools',
            qanda: [
              {
                question:
                  'Where can I find marketing tools for winmasters products?',
                answer: [
                  `Once your winmasters Affiliates account has been approved, you will
                                be able to log in and find a range of text links, banners and more
                                in the ‘Media Gallery’ section of our interface.`,
                ],
              },
              {
                question:
                  'Can I request a special marketing tool for my website?',
                answer: [
                  `Special, exclusive tools can be arranged. Contact your affiliate
                                manager to discuss the possibilities.`,
                ],
              },
              {
                question:
                  'Can I offer visitors to my website an incentive to sign up?',
                answer: [
                  `winmasters website offer a range of incentives to new players. These
                                bonuses vary according to the product you are promoting and the
                                market which you are targeting. To check the latest promotions for
                                the product and market you are targeting, please contact your
                                affiliate manager.`,
                ],
              },
              {
                question: 'Do you offer bonus codes?',
                answer: [
                  `Yes, we can offer bonus codes for winmasters. Please contact your
                                affiliate manager to request one.`,
                ],
              },
            ],
          },
          {
            title: 'Earnings and Payments',
            qanda: [
              {
                question: 'What are the available payment methods?',
                answer: [
                  `winmasters offers a number of payment methods including bank
                                transfer and Skrill (formerly Moneybookers). Please make sure that
                                your payment options are kept up-to-date under My Account & Payment
                                Information.`,
                ],
              },
              {
                question: 'When is my commission paid?',
                answer: [
                  ` We pay our affiliates 45 – 60 days upon payment request with a
                                minimum payment of 100 Euros. If you haven’t reached that level then
                                your earnings will be carried over to the following month.`,
                ],
              },
              {
                question: 'Where can I see my earnings?',
                answer: [
                  `You can view your statistics at any time via the Reports > Earnings
                                Reports section in the winmasters Affiliates interface.`,
                ],
              },
              {
                question: 'Is there a limit in my earnings?',
                answer: [
                  `There is no limit! We offer competitive and flexible reward plans to
                                reflect the quantity and quality of the players which you send to
                                us. For further details, please contact your affiliate manager.`,
                ],
              },
              {
                question:
                  'Where can I see my commission status and other information?',
                answer: [
                  `As soon as you log into your affiliate account, your payment
                                information will be displayed on the main page. More detailed
                                information can be obtained by clicking onto ‘Reports’, ‘Earnings
                                Report’ or ‘Payment Report’.`,
                ],
              },
              {
                question: `Are Sub-affiliates earnings paid on the same date as my regular
                                payments?`,
                answer: [
                  `Yes, your sub-affiliate commission is paid together with your
                                regular affiliate earnings every month. You can view your
                                sub-affiliates under Reports > Earnings Reports > Sub-Affiliates.`,
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
function languageSelector(data) {
  return texts[data.country][data.language];
}
export default languageSelector;
