import React, { useEffect, useState } from 'react';
import './custom-select-styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCountry,
  setLanguage,
  selectTextData,
  selectLanguage,
} from '../../redux/user/userSlice';
import arrowUp from '../../assets/images/arrow-up.svg';
import cyLogoDesk from '../../assets/images/cy_logo_desk.svg';
import cyLogoMob from '../../assets/images/cy_logo_mob.svg';
import grLogoDesk from '../../assets/images/gr_logo_desk.svg';
import grLogoMob from '../../assets/images/gr_logo_mob.svg';
import roLogoDesk from '../../assets/images/ro_logo_desk.svg';
import roLogoMob from '../../assets/images/ro_logo_mob.svg';
import comLogoDesk from '../../assets/images/com_logo_desk.svg';
import comLogoMob from '../../assets/images/com_logo_mob.svg';
import enFlagLanguage from '../../assets/images/en_flag.svg';
import esFlagLanguage from '../../assets/images/es_flag.svg';
import grFlagLanguage from '../../assets/images/gr_flag.svg';
import roFlagLanguage from '../../assets/images/ro_flag.svg';
import { selectIsMobile } from '../../redux/navigator/navigatorSlice';
import { useLocation } from 'react-router';
function CustomSelect({ isScrolled }) {
  const dispatch = useDispatch();
  const currentCountry = useSelector(selectCountry);
  const currentLanguage = useSelector(selectLanguage);
  const textData = useSelector(selectTextData);
  const [isopen, setisopen] = useState(false);
  const [buttonText, setButtonText] = useState(null);
  const [buttonFlag, setButtonFlag] = useState(null);
  const isMobile = useSelector(selectIsMobile);
  const { key } = useLocation();
  const handleClick = (e) => {
    //handles the user selection(language change)
    dispatch(
      setLanguage({
        country: e.target.dataset.country,
        language: e.target.dataset.language,
      })
    );
    localStorage.setItem('preferedCountry', e.target.dataset.country);
    localStorage.setItem('preferedLanguage', e.target.dataset.language);
  };
  const handleOpen = (e) => {
    setisopen(!isopen);
  };
  useEffect(() => {
    //runs every time the language changes to update the text in the button(current country)
    if (textData.navigation) {
      setButtonText(
        textData.navigation.countriesShort[currentCountry]
          ? textData.navigation.countriesShort[currentCountry]
          : 'COM'
      );
      if (currentLanguage === 'english') {
        setButtonFlag(enFlagLanguage);
      } else {
        switch (currentCountry) {
          case 'greece':
            setButtonFlag(grFlagLanguage);
            break;
          case 'romania':
            setButtonFlag(roFlagLanguage);
            break;
          case 'cyprus':
            setButtonFlag(grFlagLanguage);
            break;
          case 'com':
            setButtonFlag(esFlagLanguage);
            break;
          default:
            setButtonFlag(enFlagLanguage);
            break;
        }
      }
    }
  }, [textData]);

  useEffect(() => {
    if (isopen) {
      setisopen(false);
    }
  }, [key, textData]);
  useEffect(() => {
    if (isopen) {
      window.addEventListener('click', winEvent, true);
    }
    return () => {
      window.removeEventListener('click', winEvent, true);
    };
  }, [isopen]);
  function winEvent(e) {
    if (e.target.id === 'lang-button') {
      //the event will be hundled by the button click event
    } else if (e.target.dataset?.noclose === undefined) {
      setisopen(!isopen);
    }
  }

  return !textData.navigation ? null : (
    <div className='custom-select'>
      {!isMobile ? (
        <button
          aria-label='Language selection'
          className={`flex-center-r ${isScrolled && 'scr-grey'}`}
          id='lang-button'
          style={{ width: 'auto' }}
          onClick={handleOpen}
        >
          {buttonText}
          <img src={buttonFlag} alt='' style={{ marginLeft: '10px' }} />
        </button>
      ) : (
        <button
          aria-label='Language selection'
          className='flex-center-r'
          id='lang-button'
          onClick={handleOpen}
        >
          <div className='flex-center-r'>
            {buttonText}
            <img src={buttonFlag} alt='' />
          </div>
          <img
            className='custom-select-arrow'
            src={arrowUp}
            style={
              !isopen ? { transform: 'scaleY(-1)' } : { transform: 'scaleY(1)' }
            }
            alt=''
          />
        </button>
      )}
      <div
        data-noclose='true'
        className='options'
        style={
          isopen
            ? { visibility: 'visible', height: '437px' }
            : { visibility: 'hidden', height: '0px' }
        }
      >
        <ul data-noclose='true' className='flex-center-c'>
          <li data-noclose='true' className='skin_logos'>
            <img src={isMobile ? grLogoMob : grLogoDesk} alt='' />
          </li>
          <li
            data-language='english'
            data-country='greece'
            onClick={(e) => handleClick(e)}
          >
            <img src={enFlagLanguage} alt='' />
            {textData.navigation.language.greece.english}
          </li>
          <li
            data-language='native'
            data-country='greece'
            onClick={(e) => handleClick(e)}
          >
            <img src={grFlagLanguage} alt='' />
            {textData.navigation.language.greece.native}
          </li>
          <li data-noclose='true' className='seperator'></li>
          <li data-noclose='true' className='skin_logos'>
            <img src={isMobile ? roLogoMob : roLogoDesk} alt='' />
          </li>
          <li
            data-language='english'
            data-country='romania'
            onClick={(e) => handleClick(e)}
          >
            <img src={enFlagLanguage} alt='' />
            {textData.navigation.language.romania.english}
          </li>
          <li
            data-language='native'
            data-country='romania'
            onClick={(e) => handleClick(e)}
          >
            <img src={roFlagLanguage} alt='' />
            {textData.navigation.language.romania.native}
          </li>
          <li data-noclose='true' className='seperator'></li>
          <li data-noclose='true' className='skin_logos'>
            <img src={isMobile ? cyLogoMob : cyLogoDesk} alt='' />
          </li>
          <li
            data-language='english'
            data-country='cyprus'
            onClick={(e) => handleClick(e)}
          >
            <img src={enFlagLanguage} alt='' />
            {textData.navigation.language.cyprus.english}
          </li>
          <li
            data-language='native'
            data-country='cyprus'
            onClick={(e) => handleClick(e)}
          >
            <img src={grFlagLanguage} alt='' />
            {textData.navigation.language.cyprus.native}
          </li>
          <li data-noclose='true' className='seperator'></li>
          <li data-noclose='true' className='skin_logos'>
            <img src={isMobile ? comLogoMob : comLogoDesk} alt='' />
          </li>
          <li
            data-language='english'
            data-country='com'
            onClick={(e) => handleClick(e)}
          >
            <img src={enFlagLanguage} alt='' />
            {textData.navigation.language.com.english}
          </li>
          <li
            data-language='spanish'
            data-country='com'
            onClick={(e) => handleClick(e)}
          >
            <img src={esFlagLanguage} alt='' />
            {textData.navigation.language.com.spanish}
          </li>
        </ul>
      </div>
    </div>
  );
}
export default CustomSelect;
