import React, { useEffect, useState } from "react";
import { selectLanguage } from "../../redux/user/userSlice";
import { useSelector } from "react-redux";
function TypingText({ text, fixedText }) {
  const [word, setWord] = useState(0);
  const [letter, setLetter] = useState(0);
  const [blink, setBlink] = useState(true);
  const [reverse, setReverse] = useState(false);
  const [reseting, setReseting] = useState(false);
  const currentLang = useSelector(selectLanguage);
  // typing
  useEffect(() => {
    if (
      letter === text[word].length + 1 &&
      word !== text.length - 1 &&
      !reverse &&
      !reseting
    ) {
      setReverse(true);
      return;
    }
    if (letter === 0 && reverse && !reseting) {
      setReverse(false);
      setWord((prev) => prev + 1);
      return;
    }
    if (
      word === text.length - 1 &&
      letter === text[word].length + 1 &&
      !reseting
    ) {
      setReseting(true);
      return;
    }
    if (
      word === text.length - 1 &&
      letter === text[word].length + 1 &&
      reseting
    ) {
      setReverse(true);
      setLetter(text[word].length);
      return;
    }
    if (word === text.length - 1 && letter === 0 && reseting) {
      setReverse(false);
      setLetter(0);
      setWord(0);
      setReseting(false);
      return;
    }
    const timeout = setTimeout(() => {
      setLetter((prev) => prev + (reverse ? -1 : 1));
    }, Math.max(reverse ? 75 : letter === text[word].length ? 2000 : 150, parseInt(Math.random() * 150)));

    return () => clearTimeout(timeout);
  }, [letter, word, reverse, reseting]);

  // blinker
  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);
  // reset on language change
  useEffect(() => {
    setWord(0);
    setLetter(0);
  }, [currentLang]);

  return (
    <>
      <h1>{fixedText}</h1>
      <h1>
        {`${text[word].substring(0, letter)}`}
        {`${blink ? "|" : " "}`}
        &#160;
      </h1>
    </>
  );
}
export default TypingText;
