import React, { useEffect, useRef } from "react";
import "./what-we-offer.scss";
import { useSelector } from "react-redux";
import { selectTextData } from "../../redux/user/userSlice";
import Slider from "../../components/slider/Slider";
import TitleDot from "../../components/title with dot/TitleDot";
import { useLocation } from "react-router";
import ball from '../../assets/images/ball.svg'
import chips from '../../assets/images/chips.svg'
function WhatWeOffer() {
  const textData = useSelector(selectTextData);
  const { hash, key } = useLocation();
  const idRef = useRef();
  useEffect(() => {
    if (idRef.current && hash.includes("what-we-offer")) {
      idRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [key]);
  return (
    !textData.homePage ? null :
    <section
      id="what-we-offer"
      ref={idRef}
      className="app__section flex-center-c"
    >
      <TitleDot>{textData.homePage.whatWeOffer.title}</TitleDot>
      <Slider
        hasTopButtons={textData.homePage.whatWeOffer.tabTitles}
        totalSlides={textData.homePage.whatWeOffer.articles.length}
        icons={[ball, chips]}
      >
        {textData.homePage.whatWeOffer.articles.map((article, index) => (
          <article key={"article" + index} className="slider-item">
            {article.titles.map((title, tIndex) => (
              <section key={"section" + tIndex}>
                <h3>{title}</h3>
                {article.paragraphs[tIndex].map((para, i) => (
                  <p key={tIndex + i + "para"}>{para}</p>
                ))}
              </section>
            ))}
          </article>
        ))}
      </Slider>
    </section>
  )
}
export default WhatWeOffer;
