import React from 'react'
import { selectIsMobile } from '../../redux/navigator/navigatorSlice';
import { useSelector } from 'react-redux';
import './touch-flash-styles.scss'
function TouchFlash(props) {  
  const isMobile = useSelector(selectIsMobile);

  // the item that show up on draggable elements like (testimonials) 
    return (  
      !isMobile ? null :
       <div id='movements' style={props.ev?.type ==='touchmove' & props.isActive
        ? {transform: `translate(${props.ev?.changedTouches[0].clientX - 45}px,${props.ev?.changedTouches[0].clientY - 80}px)`, opacity:'1', zIndex:'10'} :
        props.ev?.type ==='mousemove' & props.isActive ?
        {transform: `translate(${props.ev?.clientX - 45}px,${props.ev?.clientY - 80}px)`, opacity:'1'}:

          { opacity: 0} }>
           <div className='movements__left-line'></div>
           <div className='movements__right-line'></div>
         </div>   
    )
}
export default TouchFlash
