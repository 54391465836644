import React from 'react'
import './loader-styles.scss'
import { motion } from "framer-motion";
function Loader() {
    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut", delay: 0.3 }}
        className="page-wrapper flex-center-c"
        id='loader'
      >     
            <svg version="1.1" id="ball" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="200px" height="200px" viewBox="0 0 25 25" enableBackground="new 0 0 65 25" >
<g>
	<g>
		<g id="XMLID_27_">
			<path fill="#231F20" d="M16.087,12.419c-2.174,2.201-2.693,3.931-3.67,3.913c-1.86-0.037-2.76-5.536-5.188-3.559
				c2.635-1.689,2.908,3.875,4.752,4.122c0.969,0.129,1.68-1.531,4.089-3.472c1.576-1.271,4.151,2.724,4.041,5.046
				c0.083-0.091,0.165-0.183,0.243-0.276C21.842,16.411,17.806,10.679,16.087,12.419z"/>
		</g>
		<g id="XMLID_26_">
			<path fill="#231F20" d="M16.07,13.423c-2.409,1.94-3.12,3.601-4.089,3.472c-1.844-0.246-2.118-5.811-4.752-4.122
				c-1.174,0.956-1.457,2.627-0.718,3.948c1.428,2.556,4.137,4.306,7.275,4.369c2.478,0.047,4.77-0.932,6.325-2.621
				C20.222,16.147,17.646,12.152,16.07,13.423z"/>
		</g>
		<g id="XMLID_25_">
			<path fill="#EC1C24" d="M13.875,4.043c-4.705,0-8.519,3.814-8.519,8.519c0,0.231,0.01,0.46,0.027,0.687
				c0.919-2.696,2.014-4.284,2.848-4.414c0.054-0.008,0.107-0.01,0.16-0.006C8.274,8.602,8.208,8.345,8.208,8.071
				c0-0.907,0.736-1.643,1.644-1.643s1.645,0.736,1.645,1.643c0,0.909-0.737,1.645-1.645,1.645c-0.087,0-0.174-0.007-0.258-0.02
				c1.014,1.293,1.942,3.575,2.805,3.575c0.881,0,2.033-2.616,2.633-3.382c0.25-0.32,0.506-0.698,0.83-0.984
				c-0.887-0.192-1.551-0.98-1.551-1.924c0-1.089,0.882-1.971,1.97-1.971c1.088,0,1.969,0.882,1.969,1.971
				c0,0.65-0.315,1.228-0.803,1.586c1.25,0.449,3.687,2.416,4.574,6.495c0.242-0.791,0.373-1.63,0.373-2.5
				C22.394,7.857,18.58,4.043,13.875,4.043z"/>
		</g>
	</g>
</g>
</svg>
        </motion.div>
    )
}

export default Loader
