import React, { useEffect, useRef } from "react";
import "./about-us-styles.scss";
import checkImage from "../../assets/images/check_circle.svg";
import { useSelector } from "react-redux";
import { selectTextData } from "../../redux/user/userSlice";
import TitleDot from "../../components/title with dot/TitleDot";
import { useLocation } from "react-router";

function AboutUs() {
  const textData = useSelector(selectTextData);
  const { hash, key } = useLocation();
  const idRef = useRef();

  useEffect(() => {
    if (idRef.current && hash.slice(1) === "about-us") {
      idRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [key, hash]);

  return (
    !textData.homePage ? null :
    <section id="about-us" ref={idRef} className="app__section flex-center-c">
      <span className="circle-decoration" />
      <TitleDot> {textData.homePage.aboutUs.title} </TitleDot>
      <div>
        {textData.homePage.aboutUs.paragraphs.map((item, index) => (
          <p key={"paragraphs" + index}>{item}</p>
        ))}
      </div>
      <div>
        <TitleDot small> {textData.homePage.aboutUs.subtitle}</TitleDot>
        <ul className="about-us__ul">
          {textData.homePage.aboutUs.list.map((item, index) => (
            <li key={"check" + index}>
              <img src={checkImage} alt="" />
              <p>{item}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default AboutUs;
