import React from 'react';
import logoOly from '../../assets/images/logo-olympiakos.png';
import logoPaok from '../../assets/images/logo-paok.png';
import logoPsat from '../../assets/images/logo-psat.svg';
import logoApoel from '../../assets/images/logo-apoel.svg';
import logoAnorthosis from '../../assets/images/logo-anorthosis.svg';
import logoFace from '../../assets/images/logo-facebook.svg';
import logoYou from '../../assets/images/logo-youtube.svg';
import logoInsta from '../../assets/images/logo-instagram.svg';
import './footer-styles.scss';
import { selectTextData } from '../../redux/user/userSlice';
import { useSelector } from 'react-redux';
function Footer() {
  const textData = useSelector(selectTextData);
  return !textData.footer ? null : (
    <div className='footer flex-center-c'>
      <div className='footer-row footer flex-center-c'>
        {/* <div className='sponsors-container flex-center-c'>
          <h2>{textData.footer.sponsors}</h2>
          <div className='logos'>
            <img src={logoOly} alt='' />
            <img src={logoPaok} alt='' />
            <img src={logoPsat} alt='' />
            <img src={logoApoel} alt='' />
            <img src={logoAnorthosis} alt='' />
          </div>
        </div> */}
        {textData.footer.socialLink && (
          <div className='social-media-container flex-center-c'>
            <h2>{textData.footer.socialMedia}</h2>
            <div className='social-media-links flex-center-r'>
              <a
                href={textData.footer.socialLink.facebook}
                aria-label='winmasters facebook profile'
                target='_blank'
                rel='noreferrer'
                className='flex-center-r'
              >
                <img src={logoFace} alt='Facebook logo' />
              </a>
              <a
                href={textData.footer.socialLink.youtube}
                aria-label='winmasters youtube profile'
                target='_blank'
                rel='noreferrer'
                className='flex-center-r'
              >
                <img src={logoYou} alt='Youtube logo' />
              </a>
              <a
                aria-label='winmasters instagram profile'
                href={textData.footer.socialLink.instagram}
                target='_blank'
                rel='noreferrer'
                className='flex-center-r'
              >
                <img src={logoInsta} alt='Instagram logo' />
              </a>
            </div>
          </div>
        )}
      </div>
      <div className='copyright-container'>
        <p> {textData.footer.copyright}</p>
      </div>
    </div>
  );
}

export default Footer;
