import React, { useEffect, useRef } from "react";
import "./hero-section-styles.scss";
import mob from "../../assets/images/mob-full.png";
import mobFiller from "../../assets/images/mob-filler.png";
import lapt from "../../assets/images/lapt-full.png";
import laptFiller from "../../assets/images/lapt-filler.png";
import heroVideo from "../../assets/videos/hero.mp4";
import { useSelector } from "react-redux";
import {  selectTextData } from "../../redux/user/userSlice";
import { selectIsMobile } from "../../redux/navigator/navigatorSlice";
import { Link, useLocation } from "react-router-dom";
import TypingText from "../../components/typing text/TypingText";
import posterImg from "../../assets/images/poster-hero.jpg";

function Hero() {
  const textData = useSelector(selectTextData);
  const isMobile = useSelector(selectIsMobile);
  const { hash, key } = useLocation();
  const idRef = useRef();
  useEffect(() => {
    if (idRef.current && hash.includes("home")) {
      idRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [key, hash]);
  //testing
  const lapContainter = useRef();
  const mobContainter = useRef();
  const lapRefFiller = useRef();
  const mobRefFiller = useRef();
  const ctaRef = useRef();
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (!isMobile) {
          if (mobContainter.current) {
            mobContainter.current.style.transform = `translateX(${Math.min(
              window.scrollY / 0.3,
              1500
            )}px)`;
         
          }
          if (lapContainter.current) {
            lapRefFiller.current.style.opacity = `${Math.min(
              window.scrollY / 300,
              1
            )}`;
            lapContainter.current.style.transformOrigin = "center center";
            lapContainter.current.style.transform = ` scale(${Math.max(
              1,
              Math.min(window.scrollY / 60, 13)
            )})`;
          }
        } else {
          if (ctaRef.current) {
            ctaRef.current.style.transform = `translateY(${Math.min(
              window.scrollY / 2,
              180
            )}px)`;
          }
          if (mobContainter.current) {
            mobRefFiller.current.style.opacity = `${Math.min(
              1,
              window.scrollY / 250
            )}`;
            mobContainter.current.style.transform = `translate(-${Math.min(
              window.scrollY / 2.5,
              70
            )}px,${Math.min(500, window.scrollY / 2)}px) scale(${Math.max(
              1,
              Math.min(window.scrollY / 60, 10)
            )})`;
          }
          if (lapContainter.current) {
            lapContainter.current.style.transform = `translateX(-${Math.min(
              window.scrollY / 0.5,
              1000
            )}px)`;
          }
        }
      },
      true
    );
  }, [isMobile]);

  return (
    !textData.homePage ? null :
    <section id="hero" ref={idRef} className="app__section flex-center-c">
      <video
        src={heroVideo}
        width="100%"
        height="100%"
        autoPlay
        muted
        loop
        id="heroVideo"
        poster={posterImg}
      />
      <TypingText
        fixedText={textData.homePage.hero.title}
        text={textData.homePage.hero.typing}
      />
      {isMobile && (
        <section ref={ctaRef} className="hero__ctas flex-center-r">
          <Link to={`/register`} className="generic-button btnRed">
            {textData.homePage.hero.cta}
          </Link>
          {/* <a href={textData.navigation.registration.registrationLink} className="generic-button btnRed">
            {textData.homePage.hero.cta}
          </a> */}
        </section>
      )}

      <div className="decoContainer">
        <div ref={lapContainter} className="laptContainer himgcontainer">
          <img className="hero__fg-image" src={lapt} alt="Devices" />
          <img
            ref={lapRefFiller}
            className="hero__fg-image-filler"
            src={laptFiller}
            alt="Devices"
          />
        </div>
        <div ref={mobContainter} className="mobContainer himgcontainer">
          <img className="hero__fg-image" src={mob} alt="Devices" />{" "}
          <img
            ref={mobRefFiller}
            className="hero__fg-image-filler"
            src={mobFiller}
            alt="Devices"
          />
        </div>
      </div>
    </section>
  )
}
export default Hero;
