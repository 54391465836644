import { createSlice } from "@reduxjs/toolkit"
export const navigatorSlice = createSlice({
  name: "navigator",
  initialState: {
    isMobile: true,
    currentRoute: ''


  },
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload
    }
  },
})
export const { setIsMobile, setCurrentRoute } = navigatorSlice.actions
export const selectIsMobile = (state) => state.navigator.isMobile
export const selectCurrentRoute = (state) => state.navigator.currentRoute
export default navigatorSlice.reducer
