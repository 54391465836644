import React from "react";
function TitleDot(props) {
  return (props.small ? 
    <h2>
      {props.children}
      <span className="red">.</span>
    </h2>
   : 
    <h1>
      {props.children}
      <span className="red">.</span>
    </h1>
  );
}

export default TitleDot;
