import React, { useEffect, useRef } from 'react'
import './step-icon-styles.scss'
function StepIcon( props) {
      const ref = useRef()
      useEffect(() => {
        //handles the animation-in of the Steps Section in Home page
        let options = {
          root: null,
          rootMargin: window.innerWidth > 1100 ?'-304px' : '-104px',
          threshold: window.innerWidth < 1100 ? [0,0.5,0.6,1] : [0,0.5,1],
        }
        let observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
                if (entry.isIntersecting & entry.intersectionRatio > 0) {
                    if(window.innerWidth < 1100){
                          entry.target.style.transform = 'translateX(0)'
                          entry.target.style.opacity = 1
                       observer.unobserve(entry.target)                    
                    }
                    else{
                        setTimeout(() => {
                          //add stagger effect on the items
                          entry.target.style.transform = 'translateY(0)'
                          entry.target.style.opacity = 1
                         observer.unobserve(entry.target)
                        }, props.time * 200)
                    }   
                }       
          }) 
        }, options)
          observer.observe(ref.current)
      })
    return (
       <article ref={ref} className='step flex-center-r'>
           <img className='step__image' src={props.image} alt=""/>
           <section className='flex-center-c'>
           {props.children}
           </section>
       </article>
    )
}
export default StepIcon
 