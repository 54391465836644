import  { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import languageSelector from '../data/textData'
import { selectCountry, selectLanguage, setText } from '../redux/user/userSlice'

function useUpdateTexts() {
    const currentLang = useSelector(selectLanguage)
    const currentCountry = useSelector(selectCountry)
    const dispatch = useDispatch()
    useEffect(() => {
        if (currentCountry && currentLang) {
          const textData = languageSelector({
            country: currentCountry,
            language: currentLang,
          })
          dispatch(setText(textData))
        }
      }, [currentCountry, currentLang])
    return null
}

export default useUpdateTexts
