import React, { useEffect, useState } from 'react';
import './naviagation-styles.scss';
import logoWhite from '../../assets/images/logo_white.svg';
import logoBlack from '../../assets/images/logo_black.svg';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLanguage,
  selectCountry,
  selectTextData,
} from '../../redux/user/userSlice';
import {
  selectCurrentRoute,
  selectIsMobile,
  setCurrentRoute,
} from '../../redux/navigator/navigatorSlice';
import CustomSelect from '../custom select/CustomSelect';
function Navigation() {
  const [isScrolled, setisScrolled] = useState(false);
  const textData = useSelector(selectTextData);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isMobile = useSelector(selectIsMobile);
  const currentRoute = useSelector(selectCurrentRoute);
  const currentLang = useSelector(selectLanguage);
  const currentCountry = useSelector(selectCountry);
  const dispatch = useDispatch();
  const handleClick = (e) => {
    dispatch(setCurrentRoute(e.target?.attributes.href.value));
  };
  const handleClickMobile = () => {
    setIsNavOpen(!isNavOpen);
  };

  //hamburger menu lines manipulating
  const menuSpanStyles1 = {
    transform: 'rotate(45deg) translate(0px, 0px)',
    width: '28px',
  };
  const menuSpanStyles2 = { opacity: '0', backgroundColor: 'white' };
  const menuSpanStyles3 = {
    transform: 'rotate(-45deg) translate(0px, 0px)',
    width: '28px',
    backgroundColor: 'white',
  };
  const linksWrapperStyles = isNavOpen
    ? { transform: 'translateY(0%)' }
    : { transform: 'translateY(-200%)' };
  useEffect(() => {
    //check if the hamburger menu is open to disable the html scrolling
    const body = document.querySelector('body');
    if (isNavOpen & isMobile) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'initial';
    }
  });
  const [currentDate, setcurrentDate] = useState(null);
  // useEffect(() => {
  //   //handles the time display on the sub-navigation
  //   //runs at the start and every time a language change happens
  //   const timeInterval = setInterval(() => {
  //     const d = new Date();
  //     let l = '';
  //     switch (currentCountry) {
  //       case 'com':
  //         l = currentLang === 'english' ? 'en-US' : 'es-PE';
  //         break;
  //       case 'greece':
  //         l = currentLang === 'native' ? 'el-GR' : 'en-US';
  //         break;
  //       case 'romania':
  //         l = currentLang === 'native' ? 'ro-RO' : 'en-US';
  //         break;
  //       case 'cyprus':
  //         l = currentLang === 'native' ? 'el-GR' : 'en-US';
  //         break;

  //       default:
  //         break;
  //     }
  //     const options = {
  //       year: 'numeric',
  //       weekday: 'long',
  //       month: 'long',
  //       day: 'numeric',
  //     };
  //     setcurrentDate(
  //       `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${
  //         d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
  //       }:${
  //         d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
  //       } ${d.toLocaleDateString(l, options)}`
  //     );
  //   }, 1000);
  //   return () => clearInterval(timeInterval);
  // }, [currentLang, currentCountry]);
  //from transparent to color on scroll

  const { key } = useLocation();

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    };
    let observer = new IntersectionObserver((entries, observer) => {
      if (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setisScrolled(false);
          } else {
            setisScrolled(true);
          }
        });
      }
    }, options);
    if (document.querySelector('.checker')) {
      observer.observe(document.querySelector('.checker'));
    }
    return () => {
      observer.disconnect();
    };
  }, [key]);

  return !textData.navigation ? null : isMobile ? (
    <nav
      className='flex-center-r'
      style={{ boxShadow: isScrolled ? '0 0 4px rgba(0,0,0,0.4)' : 'none' }}
    >
      <div
        className='main-navigation flex-center-r'
        style={{ backgroundColor: isScrolled ? 'white' : 'transparent' }}
      >
        <section className='flex-center-r'>
          <button
            className='nav__menu-mobile-button '
            onClick={handleClickMobile}
            aria-label='Navigation menu'
          >
            <span
              style={
                isNavOpen
                  ? menuSpanStyles1
                  : { backgroundColor: isScrolled ? 'black' : 'white' }
              }
            ></span>
            <span
              style={
                isNavOpen
                  ? menuSpanStyles2
                  : { backgroundColor: isScrolled ? 'black' : 'white' }
              }
            ></span>
            <span
              style={
                isNavOpen
                  ? menuSpanStyles3
                  : {
                      backgroundColor: isScrolled ? 'black' : 'white',
                      width: '14px',
                    }
              }
            ></span>
          </button>
          <section className='nav__logo'>
            <Link
              to={{
                pathname: `/`,
                hash: '#home',
                state: { fromDashboard: true },
              }}
              onClick={() => setIsNavOpen(false)}
              className='flex-center-r'
            >
              {' '}
              <img
                src={isScrolled ? logoBlack : logoWhite}
                alt='Winmasters Logo'
              />
            </Link>
          </section>
        </section>
        <section className='nav__user flex-center-r'>
          <a
            className={`generic-button btnWhite mob-nav ${
              currentLang === 'ro' && 'longWordsBtn'
            }`}
            href={textData.navigation.login.loginLink}
          >
            {textData.navigation.login.login}
          </a>
          <Link
            to={`/register`}
            className={`generic-button btnRed mob-nav ${
              currentLang === 'ro' && 'longWordsBtn'
            }`}
          >
            {textData.homePage.hero.cta}
          </Link>
          {/* <a href={textData.navigation.registration.registrationLink} className={`generic-button btnRed mob-nav ${currentLang === 'ro' && 'longWordsBtn'}`}>
          {textData.homePage.hero.cta}
          </a> */}
        </section>
        <section className='nav__links-wrapper' style={linksWrapperStyles}>
          <ul className='nav__links flex-center-c'>
            <li>
              <CustomSelect isScrolled={isScrolled} />
            </li>

            <li>
              <Link
                to={{
                  pathname: `/`,
                  hash: '#home',
                  state: { fromDashboard: true },
                }}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.home}
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `/`,
                  hash: '#about-us',
                  state: { fromDashboard: true },
                }}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.aboutUs}
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `/`,
                  hash: '#what-we-offer',
                  state: { fromDashboard: true },
                }}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.whatWeOffer}
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `/`,
                  hash: '#testimonials',
                  state: { fromDashboard: true },
                }}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.testimonials}
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `/`,
                  hash: '#contact',
                  state: { fromDashboard: true },
                }}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.contact}
              </Link>
            </li>
            <li>
              <Link
                to={`/reward-tiers`}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.rewardTiers}
              </Link>
            </li>
            <li>
              <Link
                to={`/faq`}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.faq}
              </Link>
            </li>
            <li>
              <Link
                to={`/terms-and-conditions`}
                style={{ color: 'white' }}
                onClick={handleClickMobile}
              >
                {textData.navigation.navigationLinks.terms}
              </Link>{' '}
            </li>
            <li style={{ color: 'white' }}>
              © 2021 winmasters All Rights Reserved
            </li>
          </ul>
        </section>
      </div>
    </nav>
  ) : (
    <nav
      className='flex-center-c'
      style={{ boxShadow: isScrolled ? '0 0 4px rgba(0, 0, 0, .4)' : 'none' }}
    >
      <div
        className='main-navigation flex-center-r'
        style={{ backgroundColor: isScrolled ? 'white' : 'transparent' }}
      >
        <section className='nav__logo'>
          <Link
            to={{
              pathname: `/`,
              hash: '#home',
              state: { fromDashboard: true },
            }}
            onClick={handleClick}
          >
            <img
              src={isScrolled ? logoBlack : logoWhite}
              alt='Winmasters Logo'
            />
          </Link>
        </section>
        <section className='nav__links-wrapper flex-center-r'>
          <ul className='nav__links flex-center-r'>
            <li>
              <Link
                className={
                  currentRoute?.includes('#home')
                    ? isScrolled
                      ? 'activeRoute'
                      : 'activeRouteNotScrolled'
                    : undefined
                }
                style={{ color: isScrolled ? '#585858' : 'white' }}
                to={{
                  pathname: `/`,
                  hash: '#home',
                  state: { fromDashboard: true },
                }}
                onClick={handleClick}
              >
                {textData.navigation.navigationLinks.home}
              </Link>
            </li>
            <li>
              <Link
                className={
                  currentRoute?.includes('#about-us')
                    ? isScrolled
                      ? 'activeRoute'
                      : 'activeRouteNotScrolled'
                    : undefined
                }
                style={{ color: isScrolled ? '#585858' : 'white' }}
                to={{
                  pathname: `/`,
                  hash: '#about-us',
                  state: { fromDashboard: true },
                }}
                onClick={handleClick}
              >
                {textData.navigation.navigationLinks.aboutUs}
              </Link>
            </li>
            <li>
              <Link
                className={
                  currentRoute?.includes('#contact')
                    ? isScrolled
                      ? 'activeRoute'
                      : 'activeRouteNotScrolled'
                    : undefined
                }
                style={{ color: isScrolled ? '#585858' : 'white' }}
                to={{
                  pathname: `/`,
                  hash: '#contact',
                  state: { fromDashboard: true },
                }}
                onClick={handleClick}
              >
                {textData.navigation.navigationLinks.contact}
              </Link>
            </li>
            <li>
              <Link
                className={
                  currentRoute?.includes('/reward-tiers')
                    ? isScrolled
                      ? 'activeRoute'
                      : 'activeRouteNotScrolled'
                    : undefined
                }
                style={{ color: isScrolled ? '#585858' : 'white' }}
                to={`/reward-tiers`}
                onClick={handleClick}
              >
                {textData.navigation.navigationLinks.rewardTiers}
              </Link>
            </li>
          </ul>
        </section>
        <section className='user-actions-wrapper flex-center-r'>
          <CustomSelect isScrolled={isScrolled} />
          <a
            className='generic-button btnWhite'
            href={textData.navigation.login.loginLink}
          >
            {textData.navigation.login.login}
          </a>
          <Link to={`/register`} className={`generic-button btnRed`}>
            {textData.homePage.hero.cta}
          </Link>
          {/* <a href={textData.navigation.registration.registrationLink} className={`generic-button btnRed`}>
          {textData.homePage.hero.cta}
          </a> */}
        </section>
      </div>
      <div
        className='sub-navigation flex-center-r'
        style={{ backgroundColor: isScrolled ? '#E5E5E5' : 'transparent' }}
      >
        <section>
          <ul
            className='nav__links flex-center-r'
            style={{ color: isScrolled ? '#E5E5E5' : 'red' }}
          >
            <li>
              <Link
                className={
                  currentRoute?.includes('/terms-and-conditions')
                    ? isScrolled
                      ? 'subNavActiveRoute'
                      : 'subNavActiveRouteNotScrolled'
                    : undefined
                }
                style={{ color: isScrolled ? '#585858' : 'white' }}
                to={`/terms-and-conditions`}
                onClick={handleClick}
              >
                {textData.navigation.navigationLinks.terms}
              </Link>{' '}
            </li>
            <li>
              <Link
                className={
                  currentRoute?.includes('/faq')
                    ? isScrolled
                      ? 'subNavActiveRoute'
                      : 'subNavActiveRouteNotScrolled'
                    : undefined
                }
                style={{ color: isScrolled ? '#585858' : 'white' }}
                to={`/faq`}
                onClick={handleClick}
              >
                {textData.navigation.navigationLinks.faq}
              </Link>
            </li>
          </ul>
        </section>
        <section>
          <p style={{ color: isScrolled ? '#585858' : 'white' }}>
            {currentDate && currentDate}
          </p>
        </section>
      </div>
    </nav>
  );
}
export default Navigation;
